import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { theme } from '@src/styles';
import { getPageTitle } from '@src/lib';
import { useHeader } from '@src/hooks/useHeader';
import { EnterCode } from './EnterCode';
import { REMOVE_LOGGER_SCREENS } from './constant';
import { CheckCode } from './CheckCode';
import { RemovalSuccess } from './RemovalSuccess';
import { ScanCode } from './ScanCode';

const Stack = createNativeStackNavigator();

export const RemoveLoggerStack = () => {
    const { screenOptions } = useHeader();

    return (
        <Stack.Navigator
            screenOptions={{
                ...screenOptions,
                title: getPageTitle(),
                headerShown: true,
                contentStyle: {
                    backgroundColor: theme.palette.common.white,
                },
            }}
        >
            <Stack.Screen name={REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE} component={EnterCode} />
            <Stack.Screen name={REMOVE_LOGGER_SCREENS.SCAN_CODE} component={ScanCode} />
            <Stack.Screen name={REMOVE_LOGGER_SCREENS.CHECK_CODE} component={CheckCode} />
            <Stack.Screen
                name={REMOVE_LOGGER_SCREENS.REMOVAL_SUCCESS}
                component={RemovalSuccess}
                options={{ headerShown: false }}
            />
        </Stack.Navigator>
    );
};
