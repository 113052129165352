import React, {
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { Platform, View, Text } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import {
    ResponseType,
    makeRedirectUri,
    useAuthRequest,
    useAutoDiscovery,
    exchangeCodeAsync,
    fetchUserInfoAsync,
    dismiss,
    refreshAsync,
    revokeAsync,
    TokenTypeHint,
} from 'expo-auth-session';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
    ACCESS_TOKEN,
    ACCESS_TOKEN_EXPIRATION,
    LOGIN_TIME,
    REFRESH_TOKEN,
    USER_INFO,
} from '@src/constants';
import { url, realm, clientId } from '@src/constants/env';

import { useNotification } from '@src/hooks/useNotification';
import {
    StoreContextInterface,
    UserInfo,
    Layout,
} from './dataTypes';

import {
    initialExtendedProfile,
    initialUserInfo,
    initialLayout,
} from './lib';

export const StoreContext = createContext<StoreContextInterface>({
    auth: {
        accessToken: '',
        login: () => {},
        logout: () => {},
        isLogin: false,

    },
    userInfo: initialUserInfo,
    layout: initialLayout,
    setLayout: () => {},
});

WebBrowser.maybeCompleteAuthSession();

export const Store = ({ children }) => {
    const { showError } = useNotification();
    const discovery = useAutoDiscovery(`${url}realms/${realm}`);
    const [accessToken, setAccessToken] = useState('');
    const [accessTokenExpiration, setAccessTokenExpiration] = useState<number | null>(null);
    const [refreshToken, setRefreshToken] = useState('');
    const [isRefreshingToken, setIsRefreshingToken] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    // TODO: fix type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [extendedProfile, setExtendedProfile] = useState<any>(initialExtendedProfile);
    const [layout, setLayout] = useState<Layout>(initialLayout);

    const LOGOUT_TIME_IN_MS = 10 * 60 * 60 * 1000;
    const REFRESH_TIME_IN_MS = 5 * 60 * 1000;

    const redirectUri = makeRedirectUri({
        scheme: 'skytrack',
        path: 'Actions',
    });

    const [request, , promptAsync] = useAuthRequest(
        {
            responseType: ResponseType.Code,
            clientId,
            redirectUri,
            scopes: ['openid', 'profile'],
        },
        discovery,
    );

    const storeAuthData = async (
        accessToken: string,
        refreshToken: string,
        expirationTime: number,
        userInfo,
        currentTime: number,
    ) => {
        const userInfoStorage = JSON.stringify(userInfo);

        if (Platform.OS === 'web') {
            localStorage.setItem(ACCESS_TOKEN, accessToken);
            localStorage.setItem(USER_INFO, userInfoStorage);
            localStorage.setItem(REFRESH_TOKEN, refreshToken);
            localStorage.setItem(ACCESS_TOKEN_EXPIRATION, expirationTime.toString());
            localStorage.setItem(LOGIN_TIME, currentTime.toString());
        } else {
            await AsyncStorage.multiSet([
                [ACCESS_TOKEN, accessToken],
                [USER_INFO, userInfoStorage],
                [REFRESH_TOKEN, refreshToken],
                [ACCESS_TOKEN_EXPIRATION, expirationTime.toString()],
                [LOGIN_TIME, currentTime.toString()],
            ]);
        }
    };

    const login = async () => {
        if (!request) return;
        setIsLogin(true);
        try {
            const codeResponse = await promptAsync();

            if (codeResponse?.type === 'success' && discovery) {
                const exchangeCodeData = await exchangeCodeAsync(
                    {
                        clientId,
                        code: codeResponse.params.code,
                        extraParams: request.codeVerifier ? { code_verifier: request.codeVerifier } : undefined,
                        redirectUri,
                    },
                    discovery,
                );

                const { accessToken, refreshToken, expiresIn } = exchangeCodeData;
                const currentTime = new Date().getTime();
                const expirationTime = currentTime + expiresIn * 1000;

                setRefreshToken(refreshToken);
                setAccessToken(accessToken);
                setAccessTokenExpiration(expirationTime);

                const userInfo = await fetchUserInfoAsync({ accessToken }, discovery);

                setExtendedProfile(userInfo);
                await storeAuthData(accessToken, refreshToken, expirationTime, userInfo, currentTime);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
            showError('Login failed. Please try again.');
        } finally {
            setIsLogin(false);
        }
    };

    const logout = async () => {
        try {
            if (Platform.OS === 'web') {
                localStorage.removeItem(ACCESS_TOKEN);
                localStorage.removeItem(USER_INFO);
                localStorage.removeItem(REFRESH_TOKEN);
                localStorage.removeItem(ACCESS_TOKEN_EXPIRATION);
                localStorage.removeItem(LOGIN_TIME);
            } else {
                await AsyncStorage.multiRemove([
                    ACCESS_TOKEN,
                    USER_INFO,
                    REFRESH_TOKEN,
                    ACCESS_TOKEN_EXPIRATION,
                    LOGIN_TIME,
                ]);
            }

            await revokeAsync({
                token: refreshToken,
                tokenTypeHint: TokenTypeHint.RefreshToken,
                clientId,
            }, discovery);

            dismiss();
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }

        setAccessToken('');
        setExtendedProfile(initialExtendedProfile);
    };

    const loadAuthData = async () => {
        if (accessToken && extendedProfile) return;

        setIsLogin(true);

        try {
            if (Platform.OS === 'web') {
                const accessTokenStorage = localStorage.getItem(ACCESS_TOKEN);
                const userInfoStorage = localStorage.getItem(USER_INFO);
                const refreshTokenStorage = localStorage.getItem(REFRESH_TOKEN);
                const accessTokenExpirationStorage = localStorage.getItem(ACCESS_TOKEN_EXPIRATION);
                const loginTimeStorage = localStorage.getItem(LOGIN_TIME);

                if (accessTokenStorage) {
                    setAccessToken(accessTokenStorage);
                }

                if (userInfoStorage) {
                    setExtendedProfile(JSON.parse(userInfoStorage));
                }

                if (refreshTokenStorage) {
                    setRefreshToken(refreshTokenStorage);
                }

                if (accessTokenExpirationStorage) {
                    setAccessTokenExpiration(parseInt(accessTokenExpirationStorage, 10));
                }

                if (loginTimeStorage) {
                    const loginTime = parseInt(loginTimeStorage, 10);
                    const currentTime = new Date().getTime();

                    if (currentTime - loginTime > LOGOUT_TIME_IN_MS) {
                        await logout();
                    }
                }
            } else {
                const accessTokenStorage = await AsyncStorage.getItem(ACCESS_TOKEN);
                const userInfoStorage = await AsyncStorage.getItem(USER_INFO);
                const refreshTokenStorage = await AsyncStorage.getItem(REFRESH_TOKEN);
                const accessTokenExpirationStorage = await AsyncStorage.getItem(ACCESS_TOKEN_EXPIRATION);
                const loginTimeStorage = await AsyncStorage.getItem(LOGIN_TIME);

                if (accessTokenStorage) {
                    setAccessToken(accessTokenStorage);
                }

                if (userInfoStorage) {
                    setExtendedProfile(JSON.parse(userInfoStorage));
                }

                if (refreshTokenStorage) {
                    setRefreshToken(refreshTokenStorage);
                }

                if (accessTokenExpirationStorage) {
                    setAccessTokenExpiration(parseInt(accessTokenExpirationStorage, 10));
                }

                if (loginTimeStorage) {
                    const loginTime = parseInt(loginTimeStorage, 10);
                    const currentTime = new Date().getTime();

                    if (currentTime - loginTime > LOGOUT_TIME_IN_MS) {
                        await logout();
                    }
                }
            }
        } catch (error) {
            global.console.log(error);
        } finally {
            setIsLogin(false);
        }
    };

    const refreshAccessToken = async () => {
        if (!accessTokenExpiration || !refreshToken || !discovery) return;

        const currentTime = new Date().getTime();
        const timeRemaining = accessTokenExpiration - currentTime;

        if (timeRemaining < REFRESH_TIME_IN_MS) {
            setIsRefreshingToken(true);

            try {
                const refreshResponse = await refreshAsync(
                    {
                        clientId,
                        refreshToken,
                    },
                    discovery,
                );

                const { accessToken, refreshToken: newRefreshToken, expiresIn } = refreshResponse;
                const newExpirationTime = currentTime + expiresIn * 1000;

                setAccessToken(accessToken);
                setRefreshToken(newRefreshToken);
                setAccessTokenExpiration(newExpirationTime);

                const userInfo = await fetchUserInfoAsync({ accessToken }, discovery);

                setExtendedProfile(userInfo);

                await storeAuthData(accessToken, newRefreshToken, newExpirationTime, userInfo, currentTime);
            } catch (error) {
                global.console.log(error);
                await logout();
            } finally {
                setIsRefreshingToken(false);
            }
        }
    };

    useEffect(() => {
        loadAuthData().then(r => r);
    }, []);

    useEffect(() => {
        setIsLogin(!request); // TODO: fix
    }, [request]);

    useEffect(() => {
        const interval = setInterval(refreshAccessToken, 180000);

        return () => clearInterval(interval);
    }, [accessTokenExpiration, refreshToken, discovery]);

    const userInfo: UserInfo = useMemo(() => {
        if (!extendedProfile.email) {
            return initialUserInfo;
        }

        return extendedProfile;
    }, [extendedProfile]);

    const contextValue = useMemo(() => ({
        auth: {
            accessToken,
            login,
            logout,
            isLogin,
            isRefreshingToken,
        },
        userInfo,
        layout,
        setLayout,
    }), [userInfo, accessToken, login, logout, layout, isLogin, isRefreshingToken]);

    if (!url || !realm || !clientId) {
        showError('Error: Missing ENV variables');

        return (
            <View>
                <Text>Error: Missing ENV variables</Text>
            </View>
        );
    }

    return (
        <StoreContext.Provider value={contextValue}>
            {children}
        </StoreContext.Provider>
    );
};
