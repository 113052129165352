import React from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { useRoute } from '@react-navigation/native';
import { SCREEN_NAMES } from '@src/Root/constant';
import { StyleSheet } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';
import { STATUSES, Status } from '../shared-components/Status';
import { Layout } from '../Layout';
import { Container } from '../Layout/Container';

export const ErrorScreen = () => {
    const navigationNoHistory = useNavigationNoHistory();
    const { t } = useTranslation();

    const route = useRoute();
    const params: { tryAgainScreen?: string } = route?.params || {};
    const tryAgainScreen = params.tryAgainScreen || SCREEN_NAMES.ACTIONS;
    const { isLandscape, hp, wp } = useScreen();

    const styles = StyleSheet.create({
        content: {
            ...(!isLandscape && {
                margin: 'auto',
                maxWidth: wp('90'),
                paddingHorizontal: wp('6'),
            }),
        },
        wrapper: {
            marginTop: hp('25'),
            ...(isLandscape && {
                marginTop: hp('30'),

            }),
        },
    });

    return (
        <Layout
            isStepperShown={false}
            verticalAlign="center"
            wrapperStyle={styles.wrapper}
        >
            <Container isPaddingTop style={styles.content}>
                <Status status={STATUSES.ERROR} />
            </Container>

            <Container>
                {
                    isLandscape
                        ? (
                            <ButtonsBlock
                                primaryBtnText={t('COMMON.TRY_AGAIN')}
                                primaryBtnOnPress={() => navigationNoHistory({ name: tryAgainScreen })}
                            />
                        )
                        : (
                            <ButtonsBlock
                                secondaryBtnText={t('COMMON.TRY_AGAIN')}
                                secondaryBtnOnPress={() => navigationNoHistory({ name: tryAgainScreen })}
                            />
                        )
                }
            </Container>
        </Layout>
    );
};
