import React, { useContext, useEffect, useState } from 'react';
import { Text, StyleSheet, View } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { font, theme } from '@src/styles';
import { Input } from '@src/components/shared-components/Input';
import { INSTANCE_TYPES } from '@src/constants';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { LayoutRemoveLogger } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { useScreen } from '@src/hooks/useScreen';
import { REMOVE_LOGGER_SCREENS } from '../constant';

export const EnterCode = ({ navigation }) => {
    const { isLandscape, wp, hp } = useScreen();
    const { t } = useTranslation();
    const { setLogger, setAsset, stepper } = useContext(RemoveLoggerContext);
    const [error, setError] = useState('');
    const [loggerNumber, setLoggerNumber] = useState('');
    const [assetNumber, setAssetNumber] = useState('');

    const onSearch = () => {
        if ((loggerNumber && assetNumber) || (!loggerNumber && !assetNumber)) {
            setError(t('REMOVE_LOGGER.ENTER_CODE_ERROR_BOTH'));
            return;
        }

        if (loggerNumber) {
            setLogger({ number: loggerNumber });
            navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, {
                type: INSTANCE_TYPES.LOGGER,
                fromScreen: REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE,
            });
        } else if (assetNumber) {
            setAsset({ number: assetNumber });
            navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, { type: INSTANCE_TYPES.ASSET });
        }

        stepper.nextStep();
    };

    const onScanCode = () => {
        // TODO: fix this
        // navigation.navigate(REMOVE_LOGGER_SCREENS.SCAN_CODE);
        navigation.reset({ index: 0, routes: [{ name: REMOVE_LOGGER_SCREENS.SCAN_CODE }] });
    };

    useEffect(() => {
        setError('');
    }, [loggerNumber, assetNumber]);

    const styles = StyleSheet.create({
        buttonsBlockForTablet: {
            width: '40%',
        },
        containerButtonsForTablet: {
            alignItems: 'center',
            width: '100%',
        },
        containerForTablet: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            marginBottom: 50,
            width: '100%',
        },
        descriptionText: {
            ...font.normal,
            fontSize: 18,
            letterSpacing: 0.1,
            lineHeight: 24,
            textAlign: 'center',
            ...(isLandscape && {
                marginLeft: wp('2.8%'),
                textAlign: 'left',
                fontSize: hp('3%'),
                lineHeight: hp('3.4%'),
            }),
        },
        descriptionWrapper: {
            marginBottom: 40,
            ...(isLandscape && {
                marginBottom: hp('8%'),
            }),
        },
        devider: {
            ...font.normal,
            color: theme.palette.common.contrastGray600,
            fontSize: 24,
            letterSpacing: 0.18,
            lineHeight: 24,
            marginBottom: 16,
            marginTop: 35,
            textAlign: 'center',
            ...(isLandscape && {
                marginLeft: wp('2.8%'),
                marginRight: wp('2.8%'),
            }),
        },
        error: {
            ...font.normal,
            color: theme.palette.error.main,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 24,
            marginBottom: 16,
            marginTop: 35,
            textAlign: 'center',
            ...(isLandscape && {
                width: '100%',
                marginTop: 0,
            }),
        },
        inputForTablet: {
            flexGrow: 1,
        },
        wrapperForTablet: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
        },
    });

    return (
        <LayoutRemoveLogger wrapperStyle={isLandscape && styles.wrapperForTablet}>
            <View style={styles.descriptionWrapper}>
                <Text style={styles.descriptionText}>{t('REMOVE_LOGGER.PROVIDE_ONE')}</Text>
            </View>
            <Container style={isLandscape && styles.containerForTablet}>
                <Input
                    label={t('REMOVE_LOGGER.ENTER_ASSET_NUMBER')}
                    value={assetNumber}
                    onChangeText={(value) => setAssetNumber(value)}
                    placeholder="XXX 9999(9) XX"
                    placeholderTextColor="#D7D7D7"
                    styleWrapper={isLandscape && styles.inputForTablet}
                    disabled={!!loggerNumber}
                />

                <Text style={styles.devider}>{t('REMOVE_LOGGER.OR')}</Text>

                <Input
                    label={t('REMOVE_LOGGER.ENTER_LOGGER_NUMBER')}
                    value={loggerNumber}
                    onChangeText={(value) => setLoggerNumber(value)}
                    placeholder="1234 1234 1234 1234"
                    placeholderTextColor="#D7D7D7"
                    styleWrapper={isLandscape && styles.inputForTablet}
                    disabled={!!assetNumber}
                />

                {!isLandscape && error && <Text style={styles.error}>{error}</Text> }
            </Container>

            {isLandscape && error && <Text style={styles.error}>{error}</Text> }

            <Container style={isLandscape && styles.containerButtonsForTablet}>
                <ButtonsBlock
                    primaryBtnText={t('COMMON.SEARCH')}
                    primaryBtnOnPress={onSearch}
                    secondaryBtnText={t('COMMON.SCAN_CODE')}
                    secondaryBtnOnPress={onScanCode}
                    style={isLandscape && styles.buttonsBlockForTablet}
                />
            </Container>
        </LayoutRemoveLogger>
    );
};
