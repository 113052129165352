export const LOGGER_INFO_URL = 'https://logger-info.skycell.ch/';

export const ACCESS_TOKEN = 'accessToken';
export const ACCESS_TOKEN_EXPIRATION = 'accessTokenExpiration';
export const LOGIN_TIME = 'loginTime';
export const REFRESH_TOKEN = 'refreshToken';
export const USER_INFO = 'userInfo';
export const IS_CREATE_ASSET_NO_WARNING = false;

export const ASSET_TYPE = {
    CONTAINER: 'CONTAINER',
    PALLET: 'PALLET',
    // PALLET: 'AIRPORT_PALLET', // TODO: Remove this
    OTHER: 'OTHER',
};

export const INSTANCE_TYPES = {
    ASSET: 'asset',
    LOGGER: 'logger',
};

export const APP_NAME = 'SkyMind';
export const APP_LONG_NAME = 'SkyMind\nAsset Pairing';

export const languageOptions = [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'French' },
    { value: 'de', label: 'German' },
    { value: 'nl', label: 'Dutch' },
];
