import { useEffect, useState } from 'react';
import * as Network from 'expo-network';
import { useNotification } from '@src/hooks/useNotification';

export const useConnectionCheck = () => {
    const { showWarningNoInternet } = useNotification();
    const [isWarningShown, setIsWarningShown] = useState(false);

    const startConnectionCheck = () => {
        const intervalId = setInterval(async () => {
            const network = await Network.getNetworkStateAsync();

            if (network.isInternetReachable || network.isConnected) {
                setIsWarningShown(false);
            } else if (!isWarningShown) {
                showWarningNoInternet();
                setIsWarningShown(true);
            }
        }, 3000);

        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        startConnectionCheck();
    }, []);

    return null;
};
