import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, ImageSourcePropType } from 'react-native';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

type Props = {
    label: string;
    id: string;
    value: string;
    setValue: (value: string) => void;
    imageSrc?: ImageSourcePropType;
};

export const RadioButtonPicture = ({ label, id, value, setValue, imageSrc }: Props) => {
    const { isLandscape, hp } = useScreen();

    const onPress = () => {
        setValue(id);
    };

    const styles = StyleSheet.create({
        image: {
            height: 76,
            marginLeft: 'auto',
            width: 108,
            ...(isLandscape && {
                height: 68.4,
                width: 97.2,
            }),
        },
        inner: {
            backgroundColor: theme.palette.primary.deepBlue,
            borderRadius: 7,
            height: 14,
            width: 14,
            ...(isLandscape && {
                height: hp('1.6%'),
                width: hp('1.6%'),
                borderRadius: hp('1.6%') / 2,
            }),
        },
        label: {
            ...font.normal,
            fontSize: 16,
            letterSpacing: 0.18,
            lineHeight: 16,
            marginLeft: 8,
            ...(isLandscape && {
                fontSize: hp('2.4%'),
                letterSpacing: 0.24,
                lineHeight: hp('2.4%'),
            }),
        },
        outer: {
            alignItems: 'center',
            borderColor: theme.palette.primary.deepBlue,
            borderRadius: 12,
            borderWidth: 1,
            display: 'flex',
            height: 24,
            justifyContent: 'center',
            width: 24,
            ...(isLandscape && {
                height: hp('3%'),
                width: hp('3%'),
                borderRadius: hp('3%') / 2,
            }),
        },
        wrapper: {
            alignItems: 'center',
            borderColor: theme.palette.primary.deepBlue,
            borderRadius: 8,
            borderStyle: 'solid',
            borderWidth: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 16,
            paddingHorizontal: 12,
            paddingVertical: 10,
            ...(isLandscape && {
                paddingVertical: hp('2%'),
                marginBottom: hp('1.6%'),
            }),
        },
        wrapperActive: {
            backgroundColor: theme.palette.common.radioButtonBg,
        },
    });

    const isActive = id === value;

    return (
        <TouchableOpacity
            style={[styles.wrapper, isActive && styles.wrapperActive]}
            onPress={onPress}
        >
            <View style={styles.outer}>
                {isActive && <View style={styles.inner} />}
            </View>
            <Text style={styles.label}>{label}</Text>
            {imageSrc && (
                <Image
                    style={styles.image}
                    source={imageSrc}
                    resizeMode="contain"
                />
            )}
        </TouchableOpacity>
    );
};
