import { HeaderRight } from '@src/components/Header/HeaderRight';
import { Logo } from '@src/components/Header/Logo';
import { theme, font } from '@src/styles';
import { useScreen } from '../useScreen';

type ScreenOptions = {
    headerLeft: () => JSX.Element,
    headerRight: () => JSX.Element,
    headerStyle: {
        backgroundColor: string,
        height?: number,
    },
    headerTitleStyle: {
        color: string,
        fontSize: number,
        lineHeight: number,
        height: number,
    },
    headerTitleAlign: 'center' | 'left',
};

export const useHeader = () => {
    const { isLandscape, hp } = useScreen();

    const screenOptions: ScreenOptions = {
        headerLeft: Logo,
        headerRight: HeaderRight,
        headerStyle: {
            backgroundColor: theme.palette.primary.darkBlue,
            ...(isLandscape && {
                height: hp('12%'),
            }),
        },
        headerTitleStyle: {
            ...font.normal,
            color: theme.palette.common.white,
            fontSize: 18,
            lineHeight: 20,
            height: 24,
            ...(isLandscape && {
                fontSize: hp('3.5%'),
                lineHeight: hp('3.5%'),
                height: hp('4%'),
            }),
        },
        headerTitleAlign: 'center',
    };

    return { screenOptions };
};
