import React, { useContext, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useTranslation } from 'react-i18next';
import { Input } from '@src/components/shared-components/Input';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { RadioButtonPicture } from '@src/components/shared-components/RadioButton/RadioButtonPicture';
import { ASSET_TYPE } from '@src/constants';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { ADD_LOGGER_SCREENS } from '../constant';
import { getEnterCodeScreenStyles } from '../styles';
import { InputRightComponent } from './partials/InputRightComponent';

export const EnterAssetCode = () => {
    const { t } = useTranslation();
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape, hp } = useScreen();
    const { asset, setAsset } = useContext(AddLoggerContext);
    const [code, setCode] = useState(asset.number || '');
    const [error, setError] = useState('');
    const setSelectedAssetType = (value) => {
        setAsset(curAsset => ({ ...curAsset, selectedAssetType: value }));
    };

    const styles = StyleSheet.create({
        inputWrapper: {
            ...(isLandscape && {
                marginTop: 0,
            }),
        },
        titleLandscape: {
            ...(isLandscape && {
                textAlign: 'left',
            }),
        },
    });

    const onConfirm = () => {
        if (!code) {
            setError(t('COMMON.FIELD_REQUIRED'));
            return;
        }
        setAsset(curAsset => ({ ...curAsset, number: code }));
        navigationNoHistory({
            name: ADD_LOGGER_SCREENS.CHECK_ASSET_CODE,
            fromScreen: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE,
        });
    };

    const onChangeText = (value) => {
        setCode(value);
        setError('');
        setAsset(curAsset => ({ ...curAsset, error: '' }));
    };

    const sharedStyles = getEnterCodeScreenStyles({ hp });

    return (
        <Layout>
            <Container>
                {isLandscape
                    ? (
                        <Title style={styles.titleLandscape}>
                            {t('ADD_LOGGER.SELECT_ASSET_TYPE')}
                        </Title>
                    )
                    : (
                        <>
                            <Title>
                                {t('ADD_LOGGER.ENTER_ASSET_CODE')}
                            </Title>
                            <View style={sharedStyles.inputWrapper}>
                                <Input
                                    value={code}
                                    onChangeText={onChangeText}
                                    placeholder="XXX 9999(9) XX"
                                    placeholderTextColor="#D7D7D7"
                                    error={error || asset.error}
                                    rightComponent={<InputRightComponent />}
                                />
                            </View>
                        </>
                    )}
                <View>
                    <RadioButtonPicture
                        label={t('COMMON.PALLET')}
                        imageSrc={require('@assets/images/enter_asset_code_pallet.png')}
                        setValue={setSelectedAssetType}
                        id={ASSET_TYPE.PALLET}
                        value={asset.selectedAssetType}
                    />
                    <RadioButtonPicture
                        label={t('COMMON.CONTAINER')}
                        imageSrc={require('@assets/images/enter_asset_code_container.png')}
                        setValue={setSelectedAssetType}
                        id={ASSET_TYPE.CONTAINER}
                        value={asset.selectedAssetType}
                    />
                    <RadioButtonPicture
                        label={t('COMMON.OTHER')}
                        imageSrc={require('@assets/images/enter_asset_code_other.png')}
                        setValue={setSelectedAssetType}
                        id={ASSET_TYPE.OTHER}
                        value={asset.selectedAssetType}
                    />
                </View>
            </Container>

            <Container>
                {isLandscape && (
                    <>
                        <Title style={styles.titleLandscape}>
                            {t('ADD_LOGGER.ENTER_ASSET_CODE')}
                        </Title>
                        <View style={[sharedStyles.inputWrapper, styles.inputWrapper]}>
                            <Input
                                value={code}
                                onChangeText={onChangeText}
                                placeholder="XXX 9999(9) XX"
                                placeholderTextColor="#D7D7D7"
                                error={error || asset.error}
                                rightComponent={<InputRightComponent />}
                            />
                        </View>
                    </>
                )}

                <ButtonsBlock
                    primaryBtnText={t('COMMON.NEXT')}
                    primaryBtnOnPress={onConfirm}
                    isPrimaryBtnDisabled={!code || !asset.selectedAssetType}
                />
            </Container>
        </Layout>
    );
};
