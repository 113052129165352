import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
    errorWrapper: {
        backgroundColor: theme.palette.common.notification.errorBg,
    },
    icon: {
        left: 23,
        position: 'absolute',
    },
    successWrapper: {
        backgroundColor: theme.palette.common.notification.successBg,
        maxWidth: 578, // TODO: change this
    },
    warningText: {
        color: theme.palette.common.black,
    },
    warningWrapper: {
        backgroundColor: theme.palette.common.notification.warningBg,
    },
    withIconWrapper: {
        paddingLeft: 66,
        position: 'relative',
    },
    wrapper: {
        alignItems: 'center',
        borderRadius: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        minHeight: 60,
        paddingBottom: 10,
        paddingLeft: 23,
        paddingRight: 23,
        paddingTop: 10,
        width: '90%',
    },
});

const ToastText = ({ text1 = '', style = {} }) => {
    const { isLandscape, hp } = useScreen();

    const stylesText1 = StyleSheet.create({
        text1: {
            ...font.normal,
            color: theme.palette.common.white,
            fontSize: 17,
            letterSpacing: -0.408,
            lineHeight: 22,
            ...(isLandscape && {
                fontSize: hp('2.5%'),
                lineHeight: hp('3.1%'),
                // TODO: check this
            }),
        },
    });

    return (
        <Text style={[stylesText1.text1, style]}>{text1}</Text>
    );
};

export const SuccessToast = ({ text1 = '' }) => (
    <View style={[styles.wrapper, styles.withIconWrapper, styles.successWrapper]}>
        <MaterialIcons
            style={styles.icon}
            name="done"
            size={28}
            color={theme.palette.common.white}
        />
        <ToastText text1={text1} />
    </View>
);

export const ErrorToast = ({ text1 = '' }) => (
    <View style={[styles.wrapper, styles.errorWrapper]}>
        <ToastText text1={text1} />
    </View>
);

export const WarningToast = ({ text1 = '' }) => (
    <View style={[styles.wrapper, styles.warningWrapper]}>
        <ToastText text1={text1} style={styles.warningText} />
    </View>
);

export const WarningNoInternetToast = () => {
    const { t } = useTranslation();

    return (
        <View style={[styles.wrapper, styles.withIconWrapper, styles.warningWrapper]}>
            <MaterialIcons
                style={styles.icon}
                name="wifi-off"
                size={28}
                color={theme.palette.common.black}
            />
            <View>
                <ToastText text1={t('WARNING.NO_INTERNET_OFFLINE')} style={styles.warningText} />
                <ToastText text1={t('WARNING.NO_INTERNET_RESTORE')} style={styles.warningText} />
            </View>
        </View>
    );
};
