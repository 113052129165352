import React, { useContext } from 'react';
import { View, StyleSheet, Text, Image, ScrollView } from 'react-native';
import { Button } from '@src/components/shared-components/Button';
import { font, paddingLeft, paddingRight, theme } from '@src/styles';
import { StoreContext } from '@src/Contexts/StoreContext';
import { APP_LONG_NAME } from '@src/constants';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';

export const Login = () => {
    const { t } = useTranslation();
    const { isLandscape } = useScreen();
    const { auth } = useContext(StoreContext);
    const { login } = auth;

    const styles = StyleSheet.create({
        bg: {
            alignItems: 'center',
            backgroundColor: theme.palette.primary.deepBlue,
            flexGrow: 1,
        },
        btn: {
            paddingBottom: 19,
            paddingTop: 19,
        },
        btnWrapper: {
            width: '100%',
        },
        container: {
            ...(isLandscape && {
                width: '48%',
            }),
        },
        logo: {
            height: 137,
            width: 136,
        },
        logoWrapper: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
        },
        title: {
            ...font.normal,
            color: theme.palette.common.white,
            fontSize: 34,
            lineHeight: 36,
            marginTop: 20,
            textAlign: 'center',
        },
        wrapper: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'flex-end',
            paddingBottom: 70,
            paddingLeft,
            paddingRight,
            width: '100%',
            ...(isLandscape && {
                paddingBottom: 0,
                flexDirection: 'row',
                width: '80%',
                justifyContent: 'space-between',
            }),
        },
    });

    return (
        <ScrollView contentContainerStyle={styles.bg}>
            <View style={styles.wrapper}>
                <View style={[styles.container, styles.logoWrapper]}>
                    <Image
                        source={require('@assets/images/logo.png')}
                        style={styles.logo}
                        resizeMode="contain"
                    />
                    <Text style={styles.title}>{APP_LONG_NAME}</Text>
                </View>
                <View style={[styles.btnWrapper, styles.container]}>
                    <Button
                        onPress={() => login()}
                        variant="primary-dark"
                        style={styles.btn}
                    >
                        {t('COMMON.LOGIN')}
                    </Button>
                </View>
            </View>
        </ScrollView>
    );
};
