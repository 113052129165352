import React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

const styles = StyleSheet.create({
    spinnerWrapper: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center',
    },
});

export const SpinnerScreen = () => {
    const { isLandscape, hp } = useScreen();

    return (
        <View style={styles.spinnerWrapper}>
            <ActivityIndicator size={isLandscape ? hp('8%') : 'large'} color={theme.palette.primary.deepBlue} />
        </View>
    );
};
