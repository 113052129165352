import React, { ReactNode } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button } from '@src/components/shared-components/Button';
import { paddingBottom, paddingLeft, paddingRight } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

type Props = {
    primaryBtnText?: string | ReactNode,
    primaryBtnOnPress?: () => void,
    isPrimaryBtnDisabled?: boolean,
    secondaryBtnText?: string,
    secondaryBtnOnPress?: () => void,
    isSecondaryBtnDisabled?: boolean,
    style?: object,
    withPadding?: boolean,
    isDirectionRow?: boolean,
};

export const ButtonsBlock = ({
    primaryBtnText = '',
    primaryBtnOnPress = () => {},
    isPrimaryBtnDisabled = false,
    secondaryBtnText = '',
    secondaryBtnOnPress = () => {},
    isSecondaryBtnDisabled = false,
    style = {},
    withPadding = false,
    isDirectionRow = false,
}: Props) => {
    const { isLandscape, wp, hp } = useScreen();

    const styles = StyleSheet.create({
        btnFirstChild: {
            marginBottom: 16,
            ...(isLandscape && {
                marginBottom: hp('2.5%'),
            }),
        },
        btnMaxWidth: {
            ...(isLandscape && {
                maxWidth: wp('23.3%'),
            }),
        },
        marginTablet: {
            ...(isLandscape && {
                marginBottom: 0,
                marginRight: wp('1.7%'),
            }),
        },
        paddingHorizontal: {
            paddingLeft,
            paddingRight,
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            paddingBottom,
            paddingTop: 30,
            ...(isLandscape && {
                paddingTop: 0,
                paddingBottom: 0,
                flexGrow: 0,
                justifyContent: 'center',
            }),
        },
        wrapperHorizontal: {
            ...(isLandscape && {
                flexDirection: 'row',
            }),
        },
    });

    return (
        <View style={[
            styles.wrapper,
            withPadding && styles.paddingHorizontal,
            isDirectionRow && styles.wrapperHorizontal,
            style,
        ]}
        >
            {primaryBtnText && (
                <Button
                    style={[
                        styles.btnFirstChild,
                        isDirectionRow && styles.marginTablet,
                        isDirectionRow && styles.btnMaxWidth,
                    ]}
                    onPress={primaryBtnOnPress}
                    variant="primary"
                    disabled={isPrimaryBtnDisabled}
                >
                    {primaryBtnText}
                </Button>
            )}
            {secondaryBtnText && (
                <Button
                    style={isDirectionRow && styles.btnMaxWidth}
                    onPress={secondaryBtnOnPress}
                    disabled={isSecondaryBtnDisabled}
                >
                    {secondaryBtnText}
                </Button>
            )}
        </View>
    );
};
