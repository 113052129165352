import { useEffect, useState } from 'react';
import { useWindowDimensions, PixelRatio } from 'react-native';

const ORIENTATION = {
    PORTRAIT: 'PORTRAIT',
    LANDSCAPE: 'LANDSCAPE',
};

export const useScreen = () => {
    const { width, height } = useWindowDimensions();
    const [orientation, setOrientation] = useState(ORIENTATION.PORTRAIT);
    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(() => {
        if (width > height) {
            setOrientation(ORIENTATION.LANDSCAPE);
        } else {
            setOrientation(ORIENTATION.PORTRAIT);
        }
    }, [width, height]);

    useEffect(() => {
        setIsLandscape(orientation === ORIENTATION.LANDSCAPE);
    }, [orientation]);

    const wp = widthPercent => {
        // Parse string percentage input and convert it to number.
        const elemWidth = typeof widthPercent === 'number' ? widthPercent : parseFloat(widthPercent);

        // Use PixelRatio.roundToNearestPixel method in order to round the layout
        // size (dp) to the nearest one that correspons to an integer number of pixels.
        return PixelRatio.roundToNearestPixel((width * elemWidth) / 100);
    };

    const hp = heightPercent => {
        // Parse string percentage input and convert it to number.
        const elemHeight = typeof heightPercent === 'number' ? heightPercent : parseFloat(heightPercent);

        // Use PixelRatio.roundToNearestPixel method in order to round the layout
        // size (dp) to the nearest one that correspons to an integer number of pixels.
        return PixelRatio.roundToNearestPixel((height * elemHeight) / 100);
    };

    return { orientation, width, height, isLandscape, wp, hp };
};
