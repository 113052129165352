import React, { useContext, useEffect, useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ASSET_TYPE } from '@src/constants';
import { useTranslation } from 'react-i18next';
import { Input } from '@src/components/shared-components/Input';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { paddingLeft, paddingRight } from '@src/styles';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { ADD_LOGGER_SCREENS } from '../constant';
import { getEnterCodeScreenStyles } from '../styles';

export const EnterLoggerCode = () => {
    const { t } = useTranslation();
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape, wp, hp } = useScreen();
    const { asset, setLogger } = useContext(AddLoggerContext);
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const onConfirm = () => {
        if (!code) {
            setError(t('COMMON.FIELD_REQUIRED'));
            return;
        }
        setLogger({ number: code });
        navigationNoHistory({
            name: ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE,
            fromScreen: ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE,
        });
    };

    const onScanCode = () => {
        navigationNoHistory({
            name: ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE,
        });
    };

    useEffect(() => {
        setError('');
    }, [code]);

    const sharedStyles = getEnterCodeScreenStyles({ hp });

    const styles = StyleSheet.create({
        image: {
            height: hp('34%'),
            width: wp('100%') - paddingRight - paddingLeft,
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
        imageContainerMobile: {
            width: '80%',
        },
        imagePallet: {
            height: hp('14%'),
        },
        imageWrapperContainerMobile: {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
        },
    });

    return (
        <Layout verticalAlign="center">
            <Container>
                {!isLandscape && (
                    <>
                        <Title>
                            {t('ADD_LOGGER.ENTER_LOGGER_CODE')}
                        </Title>
                        <View style={sharedStyles.inputWrapper}>
                            <Input
                                value={code}
                                onChangeText={(value) => setCode(value)}
                                placeholder="1234 1234 1234 1234"
                                placeholderTextColor="#D7D7D7"
                                error={error}
                            />
                        </View>
                    </>
                )}

                <View style={[sharedStyles.imageWrapper, !isLandscape && styles.imageWrapperContainerMobile]}>
                    {(asset.assetType === ASSET_TYPE.CONTAINER || asset.assetType === ASSET_TYPE.OTHER)
                        && isLandscape && (
                        <Image
                            style={styles.image}
                            source={require('@assets/images/enter_logger_code_container.png')}
                            resizeMode="contain"
                        />
                    )}
                    {(asset.assetType === ASSET_TYPE.CONTAINER || asset.assetType === ASSET_TYPE.OTHER)
                        && !isLandscape && (
                        <Image
                            style={[styles.image, styles.imageContainerMobile]}
                            source={require('@assets/images/enter_logger_code_container_mobile.png')}
                            resizeMode="contain"
                        />
                    )}

                    {asset.assetType === ASSET_TYPE.PALLET && (
                        <Image
                            style={[styles.image, styles.imagePallet]}
                            source={require('@assets/images/enter_logger_code_pallet.png')}
                            resizeMode="contain"
                        />
                    )}
                </View>
            </Container>

            <Container>
                {isLandscape && (
                    <>
                        <Title>
                            {t('ADD_LOGGER.ENTER_LOGGER_CODE')}
                        </Title>
                        <View style={sharedStyles.inputWrapper}>
                            <Input
                                value={code}
                                onChangeText={(value) => setCode(value)}
                                placeholder="1234 1234 1234 1234"
                                placeholderTextColor="#D7D7D7"
                                error={error}
                            />
                        </View>
                    </>
                )}

                <ButtonsBlock
                    primaryBtnText={t('COMMON.CONFIRM')}
                    primaryBtnOnPress={onConfirm}
                    secondaryBtnText={t('COMMON.SCAN_CODE')}
                    secondaryBtnOnPress={onScanCode}
                />
            </Container>
        </Layout>
    );
};
