import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';
import { theme } from '@src/styles';

export const SlideIndicators = ({ total, activeIndex }) => {
    const { isLandscape, hp } = useScreen();

    const doteSize = isLandscape ? hp('3%') : 16;
    const doteSizeactive = isLandscape ? hp('4.2%') : 28;

    const styles = StyleSheet.create({
        dote: {
            backgroundColor: theme.palette.common.secondShadeOfGray,
            borderColor: theme.palette.common.borderGray,
            borderRadius: doteSize / 2,
            borderWidth: 2,
            height: doteSize,
            marginRight: 16,
            width: doteSize,
        },
        doteActive: {
            backgroundColor: theme.palette.common.white,
            borderRadius: doteSizeactive / 2,
            height: doteSizeactive,
            width: doteSizeactive,
        },
        doteLast: {
            marginRight: 0,
        },
        wrapper: {
            alignItems: 'center',
            bottom: hp('10%'),
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            zIndex: 100,
        },
    });

    const dotes = new Array(total).fill(0).map((_, index) => ({ id: index, isActive: index === activeIndex }));

    return (
        <View style={styles.wrapper}>
            {dotes.map(
                ({ id, isActive }, index) => (
                    <View
                        key={id}
                        style={[
                            styles.dote,
                            isActive && styles.doteActive,
                            index === dotes.length - 1 && styles.doteLast,
                        ]}
                    />
                ),
            )}
        </View>
    );
};
