export const ADD_LOGGER_SCREENS = {
    ENTER_ASSET_CODE: 'ENTER_ASSET_CODE',
    SCAN_ASSET_CODE: 'SCAN_ASSET_CODE',
    ADD_NEW_ASSET: 'ADD_NEW_ASSET',
    CHECK_ASSET_CODE: 'CHECK_ASSET_CODE',
    ENTER_LOGGER_CODE: 'ENTER_LOGGER_CODE',
    CHECK_LOGGER_CODE: 'CHECK_LOGGER_CODE',
    SCAN_LOGGER_CODE: 'SCAN_LOGGER_CODE',
    SHOW_PAIRING_INSTRUCTION: 'SHOW_PAIRING_INSTRUCTION',
    PAIRING_SUCCESS: 'PAIRING_SUCCESS',
    TAKE_PICTURE_INIT: 'TAKE_PICTURE_INIT',
    TAKE_PICTURE_CONFIRM: 'TAKE_PICTURE_CONFIRM',
    ERROR: 'ERROR',
};
