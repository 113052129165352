import React, { useContext } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useTranslation } from 'react-i18next';
import { ASSET_TYPE } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Number } from '@src/components/Number';
import { useNotification } from '@src/hooks/useNotification';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { Ionicons } from '@expo/vector-icons';
import { font, theme } from '@src/styles';
import { ADD_LOGGER_SCREENS } from '../constant';
import { fixError } from './lib';

const styles = StyleSheet.create({
    icon: {
        marginRight: 10,
    },
    image: {
        height: 127,
        marginBottom: 32,
        marginTop: 39,
        width: 222,
    },
    number: {
        marginTop: 0,
    },
    text: {
        ...font.normal,
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 20,
    },
    textBold: {
        ...font.medium,
    },
    textCenter: {
        textAlign: 'center',
    },
    titleText: {
        fontSize: 18,
        letterSpacing: 0.15,
        lineHeight: 22,
    },
    titleWrapper: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 16,
    },
    wrapperInfo: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    wrapperWarning: {
        backgroundColor: theme.palette.common.notification.warningBg,
        borderRadius: 8,
        marginTop: 16,
        padding: 24,
    },
});

const getTextLandscape = ({ isLandscape, hp }) => ({
    ...(isLandscape && {
        fontSize: hp('2.2%'),
        lineHeight: hp('3.1%'),
    }),
});

const getTitleTextLandscape = ({ isLandscape, hp }) => ({
    ...(isLandscape && {
        fontSize: hp('2.8%'),
        lineHeight: hp('3.2%'),
    }),
});

const Warning = () => {
    const { t } = useTranslation();
    const { isLandscape, hp, wp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        titleWrapper: {
            ...(isLandscape && {
                marginBottom: hp('1.6%'),
            }),
        },
        wrapperWarning: {
            ...(isLandscape && {
                marginTop: hp('1.6%'),
                paddingVertical: hp('2%'),
                paddingHorizontal: wp('1.6%'),
            }),
        },
    });

    return (
        <View style={[styles.wrapperWarning, stylesLandscape.wrapperWarning]}>
            <View style={[styles.titleWrapper, stylesLandscape.titleWrapper]}>
                <Ionicons
                    name="warning-outline"
                    size={24}
                    style={styles.icon}
                    color={theme.palette.common.black}
                />
                <Text style={[
                    styles.text,
                    styles.titleText,
                    styles.textBold,
                    getTitleTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('ADD_LOGGER.ASSET_NOT_FOUND')}
                </Text>
            </View>

            <Text style={[styles.text, getTextLandscape({ isLandscape, hp })]}>
                {t('ADD_LOGGER.ASSET_NOT_FOUND_DESCRIPTION')}
            </Text>
        </View>
    );
};

const InfoImage = () => {
    const { asset } = useContext(AddLoggerContext);
    const { isLandscape, wp, hp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        image: {
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
    });

    return (
        <View style={styles.wrapperInfo}>
            {
                asset.selectedAssetType === ASSET_TYPE.PALLET ? (
                    <Image
                        source={require('@assets/images/enter_asset_code_pallet.png')}
                        style={[styles.image, stylesLandscape.image]}
                        resizeMode="contain"
                    />
                ) : asset.selectedAssetType === ASSET_TYPE.CONTAINER ? (
                    <Image
                        source={require('@assets/images/enter_asset_code_container.png')}
                        style={[styles.image, stylesLandscape.image]}
                        resizeMode="contain"
                    />
                ) : asset.selectedAssetType === ASSET_TYPE.OTHER ? (
                    <Image
                        source={require('@assets/images/enter_asset_code_other.png')}
                        style={[styles.image, stylesLandscape.image]}
                        resizeMode="contain"
                    />
                ) : null
            }
        </View>
    );
};

const InfoText = () => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        wrapperInfo: {
            ...(isLandscape && {
                marginTop: hp('2.5%'),
                marginBottom: hp('2.5%'),
            }),
        },
    });

    return (
        <View style={[styles.wrapperInfo, stylesLandscape.wrapperInfo]}>
            <Text style={[styles.text, styles.textCenter, getTextLandscape({ isLandscape, hp })]}>
                {t('ADD_LOGGER.WRONG_ASSET_CLICK_DESCRIPTION_CLICK')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('COMMON.NEXT')}
                </Text>
                {' '}
                {t('ADD_LOGGER.ASSET_NOT_FOUND_CLICK_DESCRIPTION_1')}
            </Text>

            <Text style={[styles.text, styles.textCenter, getTextLandscape({ isLandscape, hp })]}>
                {t('ADD_LOGGER.WRONG_ASSET_CLICK_DESCRIPTION_CLICK')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('COMMON.BACK')}
                </Text>
                {' '}
                {t('ADD_LOGGER.ASSET_NOT_FOUND_CLICK_DESCRIPTION_2')}
            </Text>
        </View>
    );
};

export const AddNewAsset = () => {
    const { t } = useTranslation();
    const navigationNoHistory = useNavigationNoHistory();
    const { showSuccess } = useNotification();
    const { isLandscape } = useScreen();
    const { asset, setAsset, stepper } = useContext(AddLoggerContext);
    const { checkPairingByAsset, addAsset } = useBackendEndpoints().requests;

    const onConfirm = async () => {
        // TODO: refactor this, it
        // has duplicate code with addAssetHandler src/AddLogger/Screens/CheckAssetOrLogger/CheckAssetOrLogger.tsx
        const result = await addAsset(asset.number, asset.selectedAssetType);

        if (result?.status === 201) {
            // asset added but we need to get its id
            const assetWithId = await checkPairingByAsset(asset.number);

            if (assetWithId?.status === 200 && assetWithId?.data) {
                const { data } = assetWithId;

                if (data.assetTypeCode) {
                    data.assetType = data.assetTypeCode; // TODO: delete this after backend assetTypeCode -> assetType
                }

                setAsset(curAsset => ({ ...curAsset, ...data, number: asset.number }));
                stepper.nextStep();
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE });
                showSuccess(t('ADD_LOGGER.ASSET_ADDED', { assetNumber: asset.number }));
            } else {
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.ERROR });
            }
        } else if (result?.status === 400) {
            // TODO: fix any
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (result?.message) {
                // TODO: fix any
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const errorMessage = fixError(result?.message);

                setAsset(curAsset => ({ ...curAsset, error: errorMessage }));
                navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
            }
        } else {
            navigationNoHistory({ name: ADD_LOGGER_SCREENS.ERROR });
        }
    };

    const onScanCode = () => {
        navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_ASSET_CODE });
    };

    return (
        <Layout verticalAlign="center">
            <Container>
                {isLandscape
                    ? (<InfoImage />)
                    : (
                        <>
                            <Number style={styles.number}>{asset.number}</Number>
                            <Warning />
                            <InfoImage />
                            <InfoText />
                        </>
                    )}
            </Container>

            <Container>
                {isLandscape && (
                    <>
                        <Number style={styles.number}>{asset.number}</Number>
                        <Warning />
                        <InfoText />
                    </>
                )}

                <ButtonsBlock
                    primaryBtnText={t('COMMON.NEXT')}
                    primaryBtnOnPress={onConfirm}
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={onScanCode}
                />
            </Container>
        </Layout>
    );
};
