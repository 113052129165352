import { useNavigation } from '@react-navigation/native';

export const useNavigationNoHistory = () => {
    const navigation = useNavigation();

    const navigationNoHistory = ({ name, fromScreen, params = {} }:
        {name: string, fromScreen?: string, params?: object}) => {
        const navigateParams = fromScreen ? { fromScreen } : {};

        navigation.reset({
            index: 0,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            routes: [{ name, params: { ...navigateParams, ...params } }],
        });
    };

    return (navigationNoHistory);
};
