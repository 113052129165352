import React from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { useScreen } from '@src/hooks/useScreen';
import { useTranslation } from 'react-i18next';
import { Ionicons } from '@expo/vector-icons';
import { font, theme } from '@src/styles';
import { StyleSheet, Text } from 'react-native';

const formatNumberWithSpaces = (num) => (num ? num.toString().replace(/(.{4})/g, '$1 ').trim() : '');

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    icon: {
        marginBottom: 35,
    },
    text: {
        ...font.normal,
        fontSize: 16,
        letterSpacing: 0.15,
        lineHeight: 24,
    },
    titleText: {
        fontSize: 24,
        letterSpacing: 0.18,
        lineHeight: 26,
        marginBottom: 16,
    },
});

export const LoggerOffline = ({ instance, primaryBtnOnPress }) => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();

    const customStyles = StyleSheet.create({
        textLandscape: {
            fontSize: hp('2.4%'),
            lineHeight: hp('3.5%'),
            marginBottom: 16,
        },
    });

    return (
        <Layout verticalAlign="center">
            <Container isPaddingTop style={styles.container}>
                <Ionicons
                    name="warning-outline"
                    size={162}
                    color={theme.palette.common.black}
                    style={styles.icon}
                />

                <Text style={[styles.text, styles.titleText]}>
                    {formatNumberWithSpaces(instance.number)}
                </Text>

                <Text style={[styles.text, styles.titleText]}>
                    {t('ADD_LOGGER.LOGGER_INACTIVE')}
                </Text>

                {!isLandscape && (
                    <Text style={styles.text}>
                        {t('ADD_LOGGER.LOGGER_INACTIVE_DESCRIPTION')}
                    </Text>
                )}
            </Container>

            <Container>
                {isLandscape && (
                    <Text style={[styles.text, customStyles.textLandscape]}>
                        {t('ADD_LOGGER.LOGGER_INACTIVE_DESCRIPTION')}
                    </Text>
                )}

                <ButtonsBlock
                    primaryBtnText={t('COMMON.TRY_AGAIN')}
                    primaryBtnOnPress={primaryBtnOnPress}
                />
            </Container>
        </Layout>
    );
};
