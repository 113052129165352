import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image, StyleSheet, View, TouchableOpacity, Text, Alert, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { font, theme } from '@src/styles';
import { Slider } from '@src/components/Slider';
import { useScreen } from '@src/hooks/useScreen';
import { SlideIndicators } from '@src/components/Slider/SlideIndicators';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { ADD_LOGGER_SCREENS } from '../constant';
import { cameraContainerHeight } from './constant';

export const PhotoWithSlides = ({ areButtonsDisabled = false }) => {
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape, hp } = useScreen();
    const { t } = useTranslation();
    const { photos, setPhotos } = useContext(AddLoggerContext);
    const wrapperRef = useRef(null);
    const [imageHeight, setImageHeight] = useState(hp(`${cameraContainerHeight}%`));
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleAddPhoto = () => {
        if (photos.length >= 5) return;

        navigationNoHistory({ name: ADD_LOGGER_SCREENS.TAKE_PICTURE_INIT });
    };

    const removePhoto = () => {
        if (photos.length === 1) {
            setPhotos([]);
            navigationNoHistory({ name: ADD_LOGGER_SCREENS.TAKE_PICTURE_INIT });
            return;
        }

        const newPhotos = photos.filter((_, index) => index !== currentIndex);

        setPhotos(newPhotos);
        setCurrentIndex(newPhotos.length === 0 ? 0 : currentIndex - 1);
    };

    const handleRemovePhotoWeb = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        const isConfirmed = confirm(t('TAKE_PICTURE.DELETE'));

        if (!isConfirmed) return;

        removePhoto();
    };

    const handleRemovePhotoAndroid = () => {
        return Alert.alert(t('TAKE_PICTURE.DELETE'), '', [
            { text: 'Delete', onPress: removePhoto },
            { text: 'Cancel', style: 'cancel' },
        ]);
    };

    const handleRemovePhoto = Platform.OS === 'web' ? handleRemovePhotoWeb : handleRemovePhotoAndroid;

    useEffect(() => {
        const clientHeight = wrapperRef?.current?.clientHeight;

        if (clientHeight) {
            setImageHeight(clientHeight);
        }
    }, [wrapperRef]);

    useEffect(() => {
        setCurrentIndex(photos.length - 1);
    }, [photos]);

    const btnSize = 74;
    const topSize = 20;
    const addPhotoDisabled = photos.length >= 5;

    const styles = StyleSheet.create({
        btn: {
            alignItems: 'center',
            backgroundColor: theme.palette.primary.darkBlue,
            borderRadius: btnSize / 2,
            display: 'flex',
            flexDirection: 'column',
            height: btnSize,
            justifyContent: 'center',
            position: 'absolute',
            right: 24,
            top: topSize,
            width: btnSize,
            zIndex: 1,
        },
        btnRemove: {
            backgroundColor: theme.palette.common.white,
            top: topSize + btnSize + 24,
        },
        btnRemoveText: {
            color: theme.palette.common.red,
        },
        btnText: {
            ...font.normal,
            color: theme.palette.common.white,
            fontSize: 12,
            lineHeight: 14,
        },
        btnTextDisabled: {
            color: theme.palette.common.thirdShadeOfGray,
        },
        disabled: {
            opacity: 0.3,
        },
        icon: {
            height: 34,
            width: 45,
        },
        iconAdd: {
            marginBottom: 2,
        },
        image: {
            height: imageHeight,
            width: '100%',
            ...(isLandscape && {
                height: hp(`${cameraContainerHeight}$`),
                flex: 1,
            }),
        },
        wrapper: {
            flex: 1,
            height: hp(`${cameraContainerHeight}$`),
            position: 'relative',
        },
        wrapperNoPhotos: {
            alignItems: 'center',
            flex: 1,
            justifyContent: 'center',
        },
    });

    if (!photos?.length) {
        return (
            <View style={styles.wrapperNoPhotos}>
                <Text>{t('TAKE_PICTURE.NO_PHOTO')}</Text>
            </View>
        );
    }

    return (
        <View style={styles.wrapper} ref={wrapperRef}>
            <TouchableOpacity
                style={styles.btn}
                onPress={handleAddPhoto}
                disabled={areButtonsDisabled}
            >
                <Image
                    style={[styles.icon, styles.iconAdd, (addPhotoDisabled || areButtonsDisabled) && styles.disabled]}
                    source={require('@assets/images/camera_plus.png')}
                    resizeMode="contain"
                />
                <Text style={[styles.btnText, (addPhotoDisabled || areButtonsDisabled) && styles.btnTextDisabled]}>
                    {t('TAKE_PICTURE.ADD')}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity
                style={[styles.btn, styles.btnRemove]}
                onPress={handleRemovePhoto}
                disabled={areButtonsDisabled}
            >
                <Image
                    style={[styles.icon, areButtonsDisabled && styles.disabled]}
                    source={require('@assets/images/delete.png')}
                    resizeMode="contain"
                />
                <Text style={[styles.btnText, styles.btnRemoveText, areButtonsDisabled && styles.btnTextDisabled]}>
                    {t('TAKE_PICTURE.REMOVE')}
                </Text>
            </TouchableOpacity>
            {photos?.length > 1 ? (
                <>
                    <Slider
                        slides={photos}
                        currentIndex={currentIndex}
                        setCurrentIndex={setCurrentIndex}
                    />
                    <SlideIndicators total={photos.length} activeIndex={currentIndex} />
                </>
            ) : (
                <Image
                    style={styles.image}
                    source={Platform.OS === 'web' ? photos[0].uri : photos[0]}
                />
            )}
        </View>
    );
};
