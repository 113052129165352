import { AxiosResponse, AxiosError } from 'axios';
import * as Network from 'expo-network';

export type Query = { [key: string]: string | number }

export const defaultGetAllQuery = { page: 0, pageSize: 1000 };

export const getConfig = (token: string, addedHeaders:object = {}) => {
    return ({
        headers: {
            Authorization: `Bearer ${token}`,
            ...addedHeaders,
        },
    });
};

export const getQueries = (obj: object) => {
    if (!obj) {
        return '';
    }

    const queryArr = Object.keys(obj).reduce((queryArrObj, key) => {
        return [
            ...queryArrObj,
            `${key}=${Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]}`,
        ];
    }, []);

    return `?${queryArr.join('&')}`;
};

export const getErrorReponseMessage = (error: { response?: AxiosResponse } = null, defaultMessage = '') => {
    try {
        const { response = null } = error || {};
        const { data = null } = response || {};

        if (Array.isArray(data) && data.length > 0) {
            const [errorData = null] = data;
            const { message = null } = errorData || {};

            return message || defaultMessage;
        } else if (data !== null && typeof data === 'object') { // typeof null also equals to 'object'
            const { parameterViolations = [] } = data;
            const collectedMessage = parameterViolations.reduce((data, issueObject, index) => {
                const { message = '', value = '' } = issueObject || {};

                return message && value
                    ? `${data}${index > 0 ? '\n' : ''}${value}: ${message}; `
                    : data;
            }, '');

            return collectedMessage || defaultMessage;
        }

        return defaultMessage;
    } catch (error) {
        return defaultMessage;
    }
};

type CatchErrors = {
    error?: AxiosError,
    defaultMessage?: string,
    logout?: () => void,
    showNotification?: (message: string) => void,
    silentErrorStatuses?: number[],
};

export const catchErrors = async ({
    error = null,
    logout = () => {},
    showNotification = alert,
    silentErrorStatuses = [],
}: CatchErrors) => {
    const { response = null } = error || {};
    const { status = null } = response || {};

    if (silentErrorStatuses.includes(status)) {
        return { data: null, status: response?.status, message: response?.data?.errorMessage };
    }

    const network = await Network.getNetworkStateAsync();

    if (!network.isInternetReachable || !network.isConnected) {
        showNotification('No internet connection. Please try again later');
        return { data: null, status: null, message: 'No internet connection' };
    } else if (status === 400) {
        const msg = response?.data?.errorMessage;

        showNotification(msg || 'Something went wrong (400). Please contact your administrator');
        return { data: null, status: response?.status, message: 'Bad request' };
    } else if (status === 401) {
        logout();
        return { data: null, status: response?.status, message: 'Unauthorized' };
    } else if (status === 403) {
        showNotification('You do not have permission to perform this action');
        return { data: null, status: response?.status, message: 'Forbidden' };
    } else if (status === 404) {
        showNotification('Request page not found (404). Please contact your administrator');
        return { data: null, status: response?.status, message: 'Not found' };
    } else if (status >= 500 && status < 600) {
        showNotification('Internal server error (500). Please try again later');
        return { data: null, status: response?.status, message: 'Internal server error' };
    } else {
        showNotification('Unknown request error');
        return { data: null, status: response?.status, message: 'Unknown request error' };
    }
};
