import React from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ErrorScreen } from '@src/components/ErrorScreen';
import { SCREEN_NAMES } from '@src/Root/constant';
import { useTranslation } from 'react-i18next';
import { theme } from '@src/styles';
import { getPageTitle } from '@src/lib';
import { useHeader } from '@src/hooks/useHeader';
import { EnterAssetCode } from './EnterAssetCode';
import { ADD_LOGGER_SCREENS } from './constant';
import { ScanAssetCode } from './ScanAssetCode';
import { CheckAsset, CheckLogger } from './CheckAssetOrLogger';
import { EnterLoggerCode } from './EnterLoggerCode';
import { ScanLoggerCode } from './ScanLoggerCode';
import { ShowPairingInstruction } from './ShowPairingInstruction';
import { PairingSuccess } from './PairingSuccess';
import { AddNewAsset } from './AddNewAsset';
import { TakePictureInit } from './TakePictureInit';
import { TakePictureConfirm } from './TakePictureConfirm';

const Stack = createNativeStackNavigator();

export const AddLoggerStack = () => {
    const { t } = useTranslation();
    const { screenOptions } = useHeader();

    return (
        <Stack.Navigator
            screenOptions={{
                ...screenOptions,
                headerTitle: t(`MENU_ITEMS.${SCREEN_NAMES.ADD_LOGGER}`),
                title: getPageTitle(),
                headerShown: true,
                contentStyle: {
                    backgroundColor: theme.palette.common.white,
                },
            }}
        >
            <Stack.Screen name={ADD_LOGGER_SCREENS.ENTER_ASSET_CODE} component={EnterAssetCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SCAN_ASSET_CODE} component={ScanAssetCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.CHECK_ASSET_CODE} component={CheckAsset} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ADD_NEW_ASSET} component={AddNewAsset} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE} component={ScanLoggerCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE} component={EnterLoggerCode} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE} component={CheckLogger} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.SHOW_PAIRING_INSTRUCTION} component={ShowPairingInstruction} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.TAKE_PICTURE_INIT} component={TakePictureInit} />
            <Stack.Screen name={ADD_LOGGER_SCREENS.TAKE_PICTURE_CONFIRM} component={TakePictureConfirm} />
            <Stack.Screen
                name={ADD_LOGGER_SCREENS.PAIRING_SUCCESS}
                component={PairingSuccess}
                options={{ headerShown: false }}
            />
            <Stack.Screen name={ADD_LOGGER_SCREENS.ERROR} component={ErrorScreen} />
        </Stack.Navigator>
    );
};
