import React from 'react';
import { AddLoggerContextWrapper } from './AddLoggerContext';
import { AddLoggerStack } from './Screens/Stack';

export const AddLoggerPath = () => {
    return (
        <AddLoggerContextWrapper>
            <AddLoggerStack />
        </AddLoggerContextWrapper>
    );
};
