import React from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { paddingBottom, paddingLeft, paddingRight, theme } from '@src/styles';
import { SCREEN_NAMES } from '@src/Root/constant';
import { Button } from '@src/components/shared-components/Button';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';

type Props = {
    // TODO: fix any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigation: any,
    children: React.ReactNode,
    primaryBtn?: {
        text: string,
        screenName: string,
    },
};

export const SuccessScreen = ({ navigation, primaryBtn, children }: Props) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();

    const onPress = () => {
        navigation.reset({ index: 0, routes: [{ name: SCREEN_NAMES.ACTIONS }] });
    };

    const primaryBtnOnPress = () => {
        navigation.reset({ index: 0, routes: [{ name: primaryBtn?.screenName }] });
    };

    const styles = StyleSheet.create({
        btn: {},
        btnWrapper: {
            flex: 1,
            justifyContent: 'flex-end',
            paddingLeft,
            paddingRight,
            ...(isLandscape && {
                paddingLeft: 0,
                paddingRight: 0,
                flexGrow: 1,
            }),
        },
        container: {
            backgroundColor: theme.palette.primary.deepBlue,
            flex: 1,
            ...(isLandscape && {
                alignItems: 'center',
                justifyContent: 'center',
            }),
        },
        image: {
            height: 202,
            width: 202,
            ...(isLandscape && {
                height: wp('18%'),
                width: wp('18%'),
            }),
        },
        imageWrapper: {
            alignItems: 'center',
            flex: 1,
            flexGrow: 2,
            justifyContent: 'center',
            ...(isLandscape && {
                flexGrow: 1,
            }),
        },
        primaryBtn: {
            backgroundColor: theme.palette.common.white,
            marginBottom: 20,
            ...(isLandscape && {
                marginTop: hp('4.2%'),
                marginBottom: hp('2.2%'),
            }),
        },
        primaryBtnText: {
            color: theme.palette.primary.deepBlue,
        },
        wrapper: {
            flex: 1,
            paddingBottom,
            width: wp('100%'),
            ...(isLandscape && {
                width: wp('80%'),
                paddingBottom: 0,
                flexDirection: 'row',
                alignItems: 'center',
            }),
        },
        wrapperChildren: {
            paddingLeft,
            paddingRight,
        },
    });

    return (
        <View style={styles.container}>
            <View style={styles.wrapper}>
                <View style={styles.imageWrapper}>
                    <Image
                        style={styles.image}
                        source={require('@assets/images/task-alt.png')}
                        resizeMode="contain"
                    />
                </View>

                {!isLandscape && (
                    <View style={styles.wrapperChildren}>
                        {children}
                    </View>
                )}

                <View style={styles.btnWrapper}>
                    {isLandscape && children}

                    {primaryBtn && (
                        <Button
                            onPress={primaryBtnOnPress}
                            variant="white"
                            style={[styles.btn, styles.primaryBtn]}
                            styleText={styles.primaryBtnText}
                        >
                            {primaryBtn.text}
                        </Button>
                    )}

                    <Button
                        onPress={onPress}
                        variant="white"
                        style={styles.btn}
                    >
                        {t('COMMON.MAIN_MENU')}
                    </Button>
                </View>
            </View>
        </View>
    );
};
