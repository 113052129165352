import React, { useCallback, useState } from 'react';
import { StyleSheet, Button, View, Text, TouchableOpacity, Platform } from 'react-native';
import { Camera, CameraType } from 'expo-camera';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { useScreen } from '@src/hooks/useScreen';
import { theme } from '@src/styles';
import { SpinnerScreen } from '../SpinnerScreen';

const color = theme.palette.common.cameraQRCode;

export const Scanner = ({ onCodeScanned, code, setCode }) => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();
    const [permission, requestPermission] = Camera.useCameraPermissions();
    const [boundingBox, setBoundingBox] = useState(null);

    const getBoxSize = (boundingBox) => ({
        // iphone has specific sizes. Needs to be adjusted

        // orientation is opoosite
        // width -> height
        // x -> y
        top: boundingBox.origin.x,
        left: boundingBox.origin.y,
        width: boundingBox.size.height,
        height: boundingBox.size.width,
    });

    const handleBarCodeScanned = useCallback((obj) => {
        let boundingBox = null;
        const { data } = obj;

        if (Platform.OS === 'android') {
            // eslint-disable-next-line prefer-destructuring
            boundingBox = obj.boundingBox;
        }
        if (Platform.OS === 'ios') {
            boundingBox = obj.bounds;
        }

        setBoundingBox(getBoxSize(boundingBox));
        setCode(data);
    }, []);

    const throttledHandleBarCodeScanned = useCallback(throttle(handleBarCodeScanned, 1000), []);

    const onSubmitCode = () => {
        onCodeScanned(code);
    };

    const styles = StyleSheet.create({
        codeText: {
            color,
            position: 'absolute',
        },
        codeWrapper: {
            borderColor: color,
            borderWidth: 2,
            position: 'absolute',
        },
        container: {
            flex: 1,
            justifyContent: 'center',
        },
        permissionText: {
            textAlign: 'center',
        },
        wrapper: {
            flexGrow: isLandscape ? 1 : 1000,
            position: 'relative',
            ...(isLandscape && {
                marginRight: wp('2.4%'),
                minHeight: hp('46.6%'),
            }),
        },
    });

    if (!permission) {
        // Camera permissions are still loading
        return <SpinnerScreen />;
    }

    if (!permission.granted) {
    // Camera permissions are not granted yet
        return (
            <View style={styles.container}>
                <Text style={styles.permissionText}>{t('COMMON.REQUESTING_CAMERA')}</Text>
                <Button onPress={requestPermission} title="grant permission" />
            </View>
        );
    }

    return (
        <View style={styles.wrapper}>
            <Camera
                type={CameraType.back}
                onBarCodeScanned={throttledHandleBarCodeScanned}
                style={StyleSheet.absoluteFillObject}
                ratio="1:1"
            />
            {boundingBox && (
                <>
                    <TouchableOpacity
                        style={[styles.codeWrapper, boundingBox]}
                        onPress={onSubmitCode}
                    />
                    <Text style={[
                        styles.codeText,
                        {
                            top: boundingBox.top + boundingBox.height,
                            left: boundingBox.left,
                        }]}
                    >
                        {code}
                    </Text>
                </>
            )}
        </View>
    );
};
