import { StyleSheet } from 'react-native';

export const getEnterCodeScreenStyles = ({ hp }) => StyleSheet.create({
    imageWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    inputWrapper: {
        marginBottom: hp('2%'),
        marginTop: hp('1.5%'),
    },
});
