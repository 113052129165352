import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { ADD_LOGGER_SCREENS } from '../../constant';

const styles = StyleSheet.create({
    button: {
        marginLeft: 20,
    },
    image: {
        height: 55,
        width: 52,
    },
});

export const InputRightComponent = () => {
    const navigationNoHistory = useNavigationNoHistory();

    const onPress = () => {
        navigationNoHistory({ name: ADD_LOGGER_SCREENS.SCAN_ASSET_CODE });
    };

    return (
        <TouchableOpacity onPress={onPress} style={styles.button}>
            <Image
                source={require('@assets/images/qr_code_button.png')}
                style={styles.image}
                resizeMode="contain"
            />
        </TouchableOpacity>
    );
};
