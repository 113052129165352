import React from 'react';
import { View, StyleSheet } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';

export const Container = ({ children, style = {}, isPaddingTop = false }) => {
    const { isLandscape, hp } = useScreen();

    const styles = StyleSheet.create({
        contentWrapper: {
            flexGrow: 1,
            ...(isLandscape && {
                flexGrow: 0,
                width: '48%',
            }),
        },
        paddingTop: {
            paddingTop: hp('10%'),
            ...(isLandscape && {
                paddingTop: 0,
            }),
        },
    });

    return (
        <View style={[
            styles.contentWrapper,
            isPaddingTop && styles.paddingTop,
            style,
        ]}
        >
            {children}
        </View>
    );
};
