import React, { useContext } from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { font, theme } from '@src/styles';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ASSET_TYPE } from '@src/constants';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { Number } from '@src/components/Number';
import { useScreen } from '@src/hooks/useScreen';

const styles = StyleSheet.create({
    icon: {
        marginRight: 10,
    },
    image: {
        height: 127,
        marginBottom: 32,
        marginTop: 39,
        width: 222,
    },
    number: {
        marginTop: 0,
    },
    text: {
        ...font.normal,
        fontSize: 14,
        letterSpacing: 0.25,
        lineHeight: 20,
    },
    textBold: {
        ...font.medium,
    },
    textCenter: {
        textAlign: 'center',
    },
    titleText: {
        fontSize: 18,
        letterSpacing: 0.15,
        lineHeight: 22,
    },
    titleWrapper: {
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 16,
    },
    wrapperInfo: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    wrapperWarning: {
        backgroundColor: theme.palette.common.notification.warningBg,
        borderRadius: 8,
        marginTop: 16,
        padding: 24,
    },
});

const getTextLandscape = ({ isLandscape, hp }) => ({
    ...(isLandscape && {
        fontSize: hp('2.2%'),
        lineHeight: hp('3.1%'),
    }),
});

const getTitleTextLandscape = ({ isLandscape, hp }) => ({
    ...(isLandscape && {
        fontSize: hp('2.8%'),
        lineHeight: hp('3.2%'),
    }),
});

const getCorrectAssetTypeValue = ({ t, asset }) => {
    return t(`COMMON.${asset.assetType}`);
};

const getSelectedAssetTypeValue = ({ t, asset }) => {
    return t(`COMMON.${asset.selectedAssetType}`);
};

const Warning = () => {
    const { t } = useTranslation();
    const { asset } = useContext(AddLoggerContext);
    const correctAssetTypeValue = getCorrectAssetTypeValue({ t, asset });
    const selectedAssetTypeValue = getSelectedAssetTypeValue({ t, asset });
    const { isLandscape, hp, wp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        titleWrapper: {
            ...(isLandscape && {
                marginBottom: hp('1.6%'),
            }),
        },
        wrapperWarning: {
            ...(isLandscape && {
                marginTop: hp('1.6%'),
                paddingVertical: hp('2%'),
                paddingHorizontal: wp('1.6%'),
            }),
        },
    });

    return (
        <View style={[styles.wrapperWarning, stylesLandscape.wrapperWarning]}>
            <View style={[styles.titleWrapper, stylesLandscape.titleWrapper]}>
                <Ionicons
                    name="warning-outline"
                    size={24}
                    style={styles.icon}
                    color={theme.palette.common.black}
                />
                <Text style={[
                    styles.text,
                    styles.titleText,
                    styles.textBold,
                    getTitleTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('ADD_LOGGER.WRONG_ASSET_TITLE')}
                    {' '}
                    {correctAssetTypeValue}
                </Text>
            </View>

            <Text style={[styles.text, getTextLandscape({ isLandscape, hp })]}>
                {t('ADD_LOGGER.WRONG_ASSET_DESCRIPTION_1')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {selectedAssetTypeValue}
                </Text>
                {' '}
                {t('ADD_LOGGER.WRONG_ASSET_DESCRIPTION_2')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {correctAssetTypeValue}
                </Text>
                {' '}
                {t('ADD_LOGGER.WRONG_ASSET_DESCRIPTION_3')}
            </Text>
        </View>
    );
};

const InfoImage = () => {
    const { asset } = useContext(AddLoggerContext);
    const { isLandscape, wp, hp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        image: {
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
    });

    return (
        <View style={styles.wrapperInfo}>
            {
                asset.assetType === ASSET_TYPE.PALLET ? (
                    <Image
                        source={require('@assets/images/enter_asset_code_pallet.png')}
                        style={[styles.image, stylesLandscape.image]}
                        resizeMode="contain"
                    />
                ) : asset.assetType === ASSET_TYPE.CONTAINER ? (
                    <Image
                        source={require('@assets/images/enter_asset_code_container.png')}
                        style={[styles.image, stylesLandscape.image]}
                        resizeMode="contain"
                    />
                ) : asset.assetType === ASSET_TYPE.OTHER ? (
                    <Image
                        source={require('@assets/images/enter_asset_code_other.png')}
                        style={[styles.image, stylesLandscape.image]}
                        resizeMode="contain"
                    />
                ) : null
            }
        </View>
    );
};

const InfoText = () => {
    const { t } = useTranslation();
    const { asset } = useContext(AddLoggerContext);
    const correctAssetTypeValue = getCorrectAssetTypeValue({ t, asset });
    const { isLandscape, hp } = useScreen();

    const stylesLandscape = StyleSheet.create({
        wrapperInfo: {
            ...(isLandscape && {
                marginTop: hp('2.5%'),
                marginBottom: hp('2.5%'),
            }),
        },
    });

    return (
        <View style={[styles.wrapperInfo, stylesLandscape.wrapperInfo]}>
            <Text style={[styles.text, styles.textCenter, getTextLandscape({ isLandscape, hp })]}>
                {t('ADD_LOGGER.WRONG_ASSET_CLICK_DESCRIPTION_CLICK')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('COMMON.NEXT')}
                </Text>
                {' '}
                {t('ADD_LOGGER.WRONG_ASSET_CLICK_DESCRIPTION_1')}
                {' '}
                <Text style={[styles.text, styles.textBold, getTextLandscape({ isLandscape, hp })]}>
                    {correctAssetTypeValue}
                    .
                </Text>
            </Text>

            <Text style={[styles.text, styles.textCenter, getTextLandscape({ isLandscape, hp })]}>
                {t('ADD_LOGGER.WRONG_ASSET_CLICK_DESCRIPTION_CLICK')}
                {' '}
                <Text style={[
                    styles.text,
                    styles.textBold,
                    getTextLandscape({ isLandscape, hp }),
                ]}
                >
                    {t('COMMON.BACK')}
                </Text>
                {' '}
                {t('ADD_LOGGER.WRONG_ASSET_CLICK_DESCRIPTION_2')}
            </Text>
        </View>
    );
};

export const WrongAssetType = ({ instance, primaryBtnOnPress, secondaryBtnOnPress }) => {
    const { t } = useTranslation();
    const { isLandscape } = useScreen();

    return (
        <Layout verticalAlign="center">
            <Container>
                {isLandscape
                    ? (<InfoImage />)
                    : (
                        <>
                            <Number style={styles.number}>{instance.number}</Number>
                            <Warning />
                            <InfoImage />
                            <InfoText />
                        </>
                    )}
            </Container>

            <Container>
                {isLandscape && (
                    <>
                        <Number style={styles.number}>{instance.number}</Number>
                        <Warning />
                        <InfoText />
                    </>
                )}
                <ButtonsBlock
                    primaryBtnText={t('COMMON.NEXT')}
                    primaryBtnOnPress={primaryBtnOnPress}
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={secondaryBtnOnPress}
                />
            </Container>
        </Layout>
    );
};
