import React from 'react';
import { Image, StyleSheet, View, FlatList } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';
import { cameraContainerHeight } from '@src/AddLogger/Screens/TakePictureConfirm/constant';

export const Slider = ({ slides, setCurrentIndex, currentIndex }) => {
    const { isLandscape, wp, hp } = useScreen();
    const screenWidth = isLandscape ? wp('39%') : wp('100%');

    const onScroll = (event) => {
        const ind = event.nativeEvent.contentOffset.x / screenWidth;
        const roundIndex = Math.round(ind);

        if (currentIndex === roundIndex) return;

        setCurrentIndex(roundIndex); // update currentIndex state when flatList is scrolled
    };

    const styles = StyleSheet.create({
        image: {
            height: hp(`${cameraContainerHeight}$`),
            width: screenWidth,
            ...(isLandscape && {
                width: wp('39%'),
            }),
        },
        wrapper: {
            flex: 1,
        },
    });

    return (
        <View style={styles.wrapper}>
            <FlatList
                data={slides}
                renderItem={({ item }) => (
                    <Image
                        key={item.uri}
                        source={item.uri}
                        style={styles.image}
                    />
                )}
                keyExtractor={item => item.uri}
                horizontal
                pagingEnabled
                showsHorizontalScrollIndicator={false}
                onScroll={onScroll}
                initialScrollIndex={slides.length - 1}
            />
        </View>
    );
};
