import React, { useContext, useEffect, useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking, Platform } from 'react-native';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import AntDesignIcons from '@expo/vector-icons/AntDesign';
import { Button } from '@src/components/shared-components/Button';
import { SCREEN_NAMES } from '@src/Root/constant';
import { StoreContext } from '@src/Contexts/StoreContext';
import { useTranslation } from 'react-i18next';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';
import { LanguageSwitcher } from '@src/components/LanguageSwitcher';
import { envName, buildDate } from '@src/constants/env';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';

export const SideBar = ({ state, navigation }) => {
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape, wp, hp } = useScreen();
    const insets = useSafeAreaInsets();
    const { userInfo, auth } = useContext(StoreContext);
    const { FlexibleRequest: getUserData } = useBackendEndpoints()?.requests || {};
    const { logout } = auth;
    const { t } = useTranslation();

    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        const getUser = async () => {
            if (!userInfo?.email) return;

            try {
                const { data: userData } = await getUserData(
                    'GET',
                    `/users/email/${userInfo.email}`,
                );

                if (!userData?.companyId) return;

                const { data: companyData } = await getUserData(
                    'GET',
                    `/companies/${userData.companyId}`,
                );

                setCompanyName(companyData.name);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
                if (error.response?.status === 401) {
                    logout();
                }
            }
        };

        getUser();
    }, [userInfo]);

    const styles = StyleSheet.create({
        close: {
            alignItems: 'center',
            backgroundColor: theme.palette.common.sidebar.closeBg,
            borderRadius: 15,
            display: 'flex',
            height: 30,
            justifyContent: 'center',
            width: 30,
            ...(isLandscape && {
                height: wp('2.8%'),
                width: wp('2.8%'),
                borderRadius: wp('1.4%'),
            }),
        },
        closeWrapper: {
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 16,
            ...(isLandscape && {
                marginTop: hp('1.7%'),
            }),
        },
        companyName: {
            ...font.normal,
            color: theme.palette.common.sidebar.userName,
            fontSize: 16,
            lineHeight: 24,
            ...(isLandscape && {
                fontSize: hp('2.2%'),
                lineHeight: hp('3.3%'),
            }),
        },
        contactSupport: {
            borderBottomColor: theme.palette.common.sidebar.borderBottom,
            borderBottomWidth: 1,
            paddingBottom: 24,
            ...(isLandscape && {
                paddingBottom: hp('2.5%'),
            }),
        },
        contactSupportText: {
            ...font.normal,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 24,
            ...(isLandscape && {
                fontSize: hp('2.2%'),
                lineHeight: hp('3.3%'),
            }),
        },
        container: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: 24,
        },
        menuItem: {
            alignItems: 'center',
            borderBottomColor: theme.palette.common.sidebar.borderBottom,
            borderBottomWidth: 1,
            color: theme.palette.common.black,
            display: 'flex',
            justifyContent: 'center',
            minHeight: 70,
            ...(isLandscape && {
                minHeight: hp('9.4%'),
            }),
        },
        menuItemText: {
            ...font.medium,
            fontSize: 14,
            letterSpacing: 1.25,
            lineHeight: 16,
            textTransform: 'uppercase',
            ...(isLandscape && {
                fontSize: hp('1.9%'),
                lineHeight: hp('2.2%'),
            }),
        },
        menuWrapper: {
            borderTopColor: theme.palette.common.sidebar.borderBottom,
            borderTopWidth: 1,
            flex: 1,
        },
        user: {
            marginBottom: 44,
            marginTop: 30,
            ...(isLandscape && {
                marginBottom: hp('4.6%'),
                marginTop: hp('3.1%'),
            }),
        },
        userName: {
            ...font.medium,
            color: theme.palette.common.sidebar.userName,
            fontSize: 20,
            lineHeight: 24,
            marginBottom: 8,
            ...(isLandscape && {
                fontSize: hp('2.7%'),
                lineHeight: hp('3.1%'),
                marginBottom: hp('0.8%'),
            }),
        },
        version: {
            ...font.normal,
            color: theme.palette.common.secondaryGray,
            marginTop: 8,
        },
        wrapper: {
            paddingLeft: 32,
            paddingRight: 32,
            ...(isLandscape && {
                paddingLeft: wp('2.2%'),
                paddingRight: wp('2.2%'),
            }),
        },
    });

    return (
        <DrawerContentScrollView
            contentContainerStyle={{ ...styles.container, paddingTop: insets.top }}
        >
            <View style={styles.wrapper}>
                <View style={styles.closeWrapper}>
                    <TouchableOpacity
                        style={styles.close}
                        onPress={() => navigation.closeDrawer()}
                    >
                        <AntDesignIcons name="close" size={isLandscape ? wp('1.5%') : 16} color="#3A3A3C" />
                    </TouchableOpacity>
                </View>

                <View style={styles.user}>
                    <Text style={styles.userName}>{userInfo.name}</Text>
                    <Text style={styles.companyName}>{companyName}</Text>
                </View>

                {state.routes.length > 1 && (
                    <View style={styles.menuWrapper}>
                        {state.routes
                            .filter(r => r.name !== SCREEN_NAMES.ACTIONS)
                            .map((route) => {
                                return (
                                    <TouchableOpacity
                                        key={route.key}
                                        style={styles.menuItem}
                                        onPress={() => {
                                            navigationNoHistory({ name: route.name });
                                        }}

                                    >
                                        <Text style={styles.menuItemText}>
                                            {t(`MENU_ITEMS.${route.name}`)}
                                        </Text>
                                    </TouchableOpacity>
                                );
                            })}
                    </View>
                )}
            </View>

            <View style={styles.wrapper}>
                <View style={styles.contactSupport}>
                    <LanguageSwitcher />
                    <Text style={{ ...styles.contactSupportText, color: theme.palette.common.black }}>
                        {t('COMMON.CONTACT_SUPPORT')}
                    </Text>
                    <Text
                        style={{ ...styles.contactSupportText, color: theme.palette.primary.darkBlue }}
                        onPress={() => Linking.openURL('mailto:support@skymind.com')}
                    >
                        support@skymind.com
                    </Text>
                    {Platform.OS !== 'web' && (
                        <Text style={styles.version}>
                            {buildDate}
                            {' '}
                            {envName !== 'PROD' ? `(${envName})` : ''}
                        </Text>
                    )}
                </View>

                <Button
                    variant="danger" onPress={() => {
                        navigation.navigate(SCREEN_NAMES.LOGIN);
                        navigation.closeDrawer();
                        logout();
                    }}
                >
                    {t('COMMON.LOGOUT')}
                </Button>
            </View>
        </DrawerContentScrollView>
    );
};
