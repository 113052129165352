import { StyleSheet } from 'react-native';

export * from './palette';

export const paddingTop = 24;
export const paddingBottom = 24;
export const paddingLeft = 32;
export const paddingRight = 32;

export const font = StyleSheet.create({
    light: {
        fontFamily: 'RobotoLight',
        fontStyle: 'normal',
        fontWeight: '300',
    },
    medium: {
        fontFamily: 'RobotoMedium',
        fontStyle: 'normal',
        fontWeight: '500',
    },
    normal: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '400',
    },
});
