import React, { useCallback, useEffect } from 'react';
import 'react-native-gesture-handler';
import { useFonts } from 'expo-font';
import Toast from 'react-native-toast-message';
import * as SplashScreen from 'expo-splash-screen';
import { Store } from '@src/Contexts/StoreContext';
import {
    SuccessToast,
    ErrorToast,
    WarningToast,
    WarningNoInternetToast,
} from '@src/components/shared-components/Toasts';
import { useScreen } from '@src/hooks/useScreen';
import { useConnectionCheck } from '@src/hooks/useConnectionCheck';
import { Root } from './src/Root';
import '@src/i18n';

SplashScreen.preventAutoHideAsync();

export default function App() {
    const { isLandscape, hp } = useScreen();

    useConnectionCheck();

    const [fontsLoaded] = useFonts({
        Roboto: require('./assets/fonts/Roboto/Roboto-Regular.ttf'),
        RobotoMedium: require('./assets/fonts/Roboto/Roboto-Medium.ttf'),
        RobotoLight: require('./assets/fonts/Roboto/Roboto-Light.ttf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    useEffect(() => {
        onLayoutRootView();
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }

    const toastConfig = {
        success: SuccessToast,
        error: ErrorToast,
        warning: WarningToast,
        warningNoInternet: WarningNoInternetToast,
    };

    return (
        <>
            <Store>
                <Root />
            </Store>
            <Toast
                config={toastConfig}
                // TODO: check this offset
                topOffset={isLandscape ? hp('13%') : 68}
                visibilityTime={3000}
            />
        </>
    );
}
