import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { font, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';

export const Number = ({ children, style = {}, type = 'medium' }) => {
    const { isLandscape, hp } = useScreen();

    const styles = StyleSheet.create({
        text: {
            ...font.normal,
            color: theme.palette.primary.darkBlue,
            fontSize: 48,
            lineHeight: 56,
            marginTop: 28,
            maxWidth: '100%',
            overflow: 'hidden',
            textAlign: 'center',
            textTransform: 'uppercase',
            ...(isLandscape && {
                ...font.light,
                fontSize: hp('6.3%'),
                lineHeight: hp('7.5%'),
                letterSpacing: -0.5,
                marginTop: hp('2.5%'),
            }),
        },
        textSmall: {
            fontSize: 36,
            lineHeight: 42,
            ...(isLandscape && {
                fontSize: hp('5%'),
                lineHeight: hp('5.8%'),
            }),
        },
    });

    return (
        <Text style={[styles.text, type === 'small' && styles.textSmall, style]}>{children}</Text>
    );
};
