import React, { useContext, useState } from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useTranslation } from 'react-i18next';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { Scanner } from '@src/components/Scanner';
import { getLoggerNumberWithoutPrefix } from '@src/lib';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { ADD_LOGGER_SCREENS } from '../constant';

export const ScanLoggerCode = () => {
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape } = useScreen();
    const { t } = useTranslation();
    const { setLogger } = useContext(AddLoggerContext);
    const [code, setCode] = useState(null);

    const onCodeScanned = () => {
        const codeWithoutPrefix = getLoggerNumberWithoutPrefix(code);

        setLogger({ number: codeWithoutPrefix });
        navigationNoHistory({
            name: ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE,
            fromScreen: ADD_LOGGER_SCREENS.SCAN_LOGGER_CODE,
        });
    };

    return (
        <Layout verticalAlign="center">
            {!isLandscape && (
                <Container>
                    <Title>
                        {t('ADD_LOGGER.SCAN_LOGGER_CODE')}
                    </Title>
                </Container>
            )}

            <Scanner onCodeScanned={onCodeScanned} code={code} setCode={setCode} />

            <Container>
                {isLandscape && (
                    <Title>
                        {t('ADD_LOGGER.SCAN_LOGGER_CODE')}
                    </Title>
                )}

                <ButtonsBlock
                    primaryBtnText={t('COMMON.SCAN')}
                    primaryBtnOnPress={onCodeScanned}
                    isPrimaryBtnDisabled={!code}
                    secondaryBtnText={t('COMMON.ENTER_MANUALLY')}
                    secondaryBtnOnPress={() => navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE })}
                />
            </Container>
        </Layout>
    );
};
