import React from 'react';
import { View, Text, StyleSheet, Image, ScrollView } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';
import { useTranslation } from 'react-i18next';
import { font, theme } from '@src/styles';

export const NoAccess = () => {
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();

    const styles = StyleSheet.create({
        image: {
            height: 165,
            width: 135,
            ...(isLandscape && {
                height: wp('15%'),
                width: wp('13%'),
            }),
        },
        text: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 14,
            letterSpacing: 0.25,
            lineHeight: 20,
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('2.4%'),
                lineHeight: hp('3%'),
            }),
        },
        textWrapper: {
            marginTop: 47,
            width: wp('60%'),
            ...(isLandscape && {
                marginTop: 0,
                width: wp('36%'),
                marginLeft: wp('4.4%'),
            }),
        },
        title: {
            fontSize: 24,
            letterSpacing: 0.18,
            lineHeight: 28,
            marginBottom: 32,
            ...(isLandscape && {
                fontSize: hp('4.5%'),
                lineHeight: hp('5%'),
                marginBottom: hp('1.7%'),
            }),
        },
        wrapper: {
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'center',
            ...(isLandscape && {
                flexDirection: 'row',
                justifyContent: 'center',
            }),
        },
    });

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Image
                style={styles.image}
                source={require('@assets/images/lock.png')}
                resizeMode="contain"
            />
            <View style={styles.textWrapper}>
                <Text style={[styles.text, styles.title]}>{t('NO_ACCESS.TITLE')}</Text>
                <Text style={styles.text}>{t('NO_ACCESS.CONTACT')}</Text>
            </View>
        </ScrollView>
    );
};
