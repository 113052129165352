import Toast from 'react-native-toast-message';

export const useNotification = () => {
    const showSuccess = (msg: string) => Toast.show({
        type: 'success',
        text1: msg,
    });

    const showError = (msg: string) => Toast.show({
        type: 'error',
        text1: msg,
    });

    const showWarning = (msg: string) => Toast.show({
        type: 'warning',
        text1: msg,
    });

    const showWarningNoInternet = () => Toast.show({
        type: 'warningNoInternet',
    });

    return (
        { showSuccess, showError, showWarning, showWarningNoInternet }
    );
};
