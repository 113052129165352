import { UserInfo, ExtendedProfile, Layout } from './dataTypes';

export const initialExtendedProfile: ExtendedProfile = {
    assignedRoles: [],
    attributes: {
        companyId: [],
    },
    username: '',
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: null,
};

export const initialLayout: Layout = {
    navigation: null,
    isRestarting: false,
};

export const initialUserInfo: UserInfo = {
    assignedRoles: [],
    avatar: '',
    companyId: null,
    name: '',
    email: '',
    groups: [],
};
