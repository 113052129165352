import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { font, paddingLeft, paddingRight, theme } from '@src/styles';
import { useScreen } from '@src/hooks/useScreen';
import { useTranslation } from 'react-i18next';

export const TakePictureDescription = ({ wrapperStyle = {} }) => {
    const { t } = useTranslation();
    const { isLandscape, hp } = useScreen();

    const styles = StyleSheet.create({
        description: {
            ...font.normal,
            fontSize: 16,
            letterSpacing: 0.15,
            lineHeight: 24,
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('2.1'),
                lineHeight: hp('2.8'),
            }),
        },
        descriptionWrapper: {
            borderColor: theme.palette.primary.deepBlue,
            borderRadius: 10,
            borderWidth: 1,
            marginBottom: 37,
            marginLeft: paddingLeft,
            marginRight: paddingRight,
            paddingBottom: 9,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 9,
            ...(isLandscape && {
                marginBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }),
        },
    });

    return (
        <View style={[styles.descriptionWrapper, wrapperStyle]}>
            <Text style={styles.description}>
                {t('TAKE_PICTURE.DESCRIPTION')}
            </Text>
        </View>
    );
};
