import React from 'react';
import SelectDropdown from 'react-native-select-dropdown';
import { font, theme } from '@src/styles';
import Ionicons from '@expo/vector-icons/Ionicons';
import { StyleSheet } from 'react-native';
import { useScreen } from '@src/hooks/useScreen';

export const Select = ({ height = 160, wrapperStyle = {}, ...props }) => {
    const { isLandscape, hp } = useScreen();

    const styles = StyleSheet.create({
        dropdownBtnStyle: {
            ...font.normal,
            backgroundColor: theme.palette.common.white,
            borderColor: theme.palette.common.borderGray,
            borderRadius: 8,
            borderWidth: 1,
            color: theme.palette.common.contrastGray600,
            fontSize: 16,
            height: 50,
            lineHeight: 16,
            marginTop: 24,
            width: '100%',
            ...(isLandscape && {
                borderRadius: hp('1%'),
                height: hp('7%'),
                width: '60%',
                fontSize: hp('3%'),
                lineHeight: hp('3%'),
            }),
        },
        dropdownBtnTxtStyle: {
            ...font.normal,
            color: theme.palette.common.contrastGray600,
            fontSize: 16,
            lineHeight: 16,
            textAlign: 'left',
            ...(isLandscape && {
                fontSize: hp('3%'),
                lineHeight: hp('3%'),
            }),
        },
        dropdownDropdownStyle: {
            backgroundColor: theme.palette.common.white,
            borderRadius: 4,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
            height,
            ...(isLandscape && {
                height: hp('20%'),
            }),
        },
        dropdownRowStyle: {
            backgroundColor: theme.palette.common.white,
            borderBottomColor: theme.palette.common.transparent,
        },
        dropdownRowTxtStyle: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 14,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 5,
            textAlign: 'left',
            ...(isLandscape && {
                fontSize: hp('3%'),
                lineHeight: hp('3.6%'),
            }),
        },
    });

    return (
        <SelectDropdown
            dropdownOverlayColor="transparent"
            buttonStyle={[styles.dropdownBtnStyle, wrapperStyle]}
            buttonTextStyle={styles.dropdownBtnTxtStyle}
            renderDropdownIcon={isOpened => {
                return (
                    <Ionicons
                        name={isOpened ? 'caret-up-sharp' : 'caret-down-sharp'}
                        color={theme.palette.common.dropdownGray}
                        size={18}
                    />
                );
            }}
            dropdownIconPosition="right"
            dropdownStyle={styles.dropdownDropdownStyle}
            rowStyle={styles.dropdownRowStyle}
            rowTextStyle={styles.dropdownRowTxtStyle}
            {...props}
        />
    );
};
