import React, { useContext, useState } from 'react';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { useTranslation } from 'react-i18next';
import { LayoutRemoveLogger } from '@src/components/Layout';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { INSTANCE_TYPES } from '@src/constants';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { Scanner } from '@src/components/Scanner';
import { getLoggerNumberWithoutPrefix } from '@src/lib';
import { useScreen } from '@src/hooks/useScreen';
import { REMOVE_LOGGER_SCREENS } from '../constant';

const fromScreen = REMOVE_LOGGER_SCREENS.SCAN_CODE;

export const ScanCode = ({ navigation }) => {
    const { isLandscape } = useScreen();
    const { t } = useTranslation();
    const { checkPairingByAsset, checkLoggerType } = useBackendEndpoints().requests;
    const { setAsset, setLogger, stepper } = useContext(RemoveLoggerContext);
    const [code, setCode] = useState(null);

    const onCodeScanned = async () => {
        const responseAsset = await checkPairingByAsset(code);

        if (responseAsset?.data?.assetNumber?.toUpperCase() === code?.toUpperCase()) {
            setLogger({ number: null });
            setAsset(curAsset => ({ ...curAsset, number: code }));
            stepper.nextStep();
            navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, { type: INSTANCE_TYPES.ASSET, fromScreen });
            return;
        }

        const codeWithoutPrefix = getLoggerNumberWithoutPrefix(code);
        const resultCheckLoggerType = await checkLoggerType(codeWithoutPrefix);
        const { data } = resultCheckLoggerType;

        if (data?.resultList?.length !== 0) {
            setAsset({ number: null });
            setLogger(curLogger => ({ ...curLogger, number: codeWithoutPrefix }));
            stepper.nextStep();
            navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, { type: INSTANCE_TYPES.LOGGER, fromScreen });
            return;
        }

        stepper.nextStep();
        navigation.navigate(REMOVE_LOGGER_SCREENS.CHECK_CODE, { scannedNumber: code, fromScreen });
    };

    return (
        <LayoutRemoveLogger verticalAlign="center">
            {!isLandscape && (
                <Container>
                    <Title>
                        {t('REMOVE_LOGGER.SCAN_CODE')}
                    </Title>
                </Container>
            )}

            <Scanner onCodeScanned={onCodeScanned} code={code} setCode={setCode} />

            <Container>
                {isLandscape && (
                    <Title>
                        {t('REMOVE_LOGGER.SCAN_CODE')}
                    </Title>
                )}
                <ButtonsBlock
                    primaryBtnText={t('COMMON.SCAN')}
                    primaryBtnOnPress={onCodeScanned}
                    isPrimaryBtnDisabled={!code}
                    secondaryBtnText={t('COMMON.ENTER_MANUALLY')}
                    secondaryBtnOnPress={() => navigation.navigate(REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE)}
                />
            </Container>
        </LayoutRemoveLogger>
    );
};
