import React from 'react';
import { RemoveLoggerContextWrapper } from './RemoveLoggerContext';
import { RemoveLoggerStack } from './Screens/Stack';

export const RemoveLoggerPath = () => {
    return (
        <RemoveLoggerContextWrapper>
            <RemoveLoggerStack />
        </RemoveLoggerContextWrapper>
    );
};
