import React, { useContext } from 'react';
import { Text, Image, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { SCREEN_NAMES } from '@src/Root/constant';
import { font, theme } from '@src/styles';
import { StoreContext } from '@src/Contexts/StoreContext';
import { APP_NAME } from '@src/constants';
import { useScreen } from '@src/hooks/useScreen';

export const Logo = () => {
    const { layout } = useContext(StoreContext);
    const { navigation } = layout;
    const { isLandscape, wp, hp } = useScreen();

    const styles = StyleSheet.create({
        image: {
            height: 30,
            width: 30,
            ...(isLandscape && {
                height: wp('3.8%'),
                width: wp('3.8%'),
            }),
        },
        text: {
            ...font.normal,
            color: theme.palette.common.white,
            fontSize: 12,
            letterSpacing: 0.4,
            lineHeight: 12,
            marginLeft: 8,
            ...(isLandscape && {
                fontSize: hp('2.1%'),
                fontWeight: '500',
                lineHeight: hp('2.1%'),
                letterSpacing: 0.15,
                marginLeft: wp('1.0%'),
            }),
        },
        wrapper: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            ...(Platform.OS === 'web' && {
                marginLeft: 16,
                ...(isLandscape && {
                    marginLeft: wp('2.5%'),
                }),
            }),
        },
    });

    return (
        <TouchableOpacity
            style={styles.wrapper}
            onPress={() => navigation?.reset({ index: 0, routes: [{ name: SCREEN_NAMES.ACTIONS }] })}
        >
            <Image
                style={styles.image}
                source={require('@assets/images/logo.png')}
                resizeMode="contain"
            />
            <Text style={styles.text}>{APP_NAME}</Text>
        </TouchableOpacity>
    );
};
