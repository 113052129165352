import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { FLOWS, Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { RadioButtonPicture } from '@src/components/shared-components/RadioButton/RadioButtonPicture';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { ADD_LOGGER_SCREENS } from '../../constant';

export const LoggerTypeSelection = ({
    loggerChoiceOptions,
    isRemovalFlow = false,
    nextScreen = '',
    backScreen = '',
    nextScreenParams = {},
}) => {
    const { t } = useTranslation();
    const { logger, setLogger } = useContext(isRemovalFlow ? RemoveLoggerContext : AddLoggerContext);
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape } = useScreen();

    const setLoggerData = ({ id, loggerType }) => {
        setLogger({ ...logger, selectedLoggerType: loggerType, id });
    };

    const styles = {
        title: {
            ...(isLandscape && {
                textAlign: 'left',
            }),
        },
    };

    return (
        <Layout verticalAlign="center" flow={isRemovalFlow ? FLOWS.REMOVE_LOGGER : FLOWS.ADD_LOGGER}>
            <Container>
                <Title style={styles.title}>
                    {t('ADD_LOGGER.SELECT_LOGGER_TYPE')}
                </Title>

                {loggerChoiceOptions?.map(({ id, loggerType }) => (
                    <RadioButtonPicture
                        key={loggerType}
                        label={t(`LOGGER_TYPES.${loggerType}`)}
                        id={id}
                        value={logger.selectedLoggerType}
                        setValue={() => setLoggerData({ id, loggerType })}
                    />
                ))}
            </Container>

            <Container>

                <ButtonsBlock
                    primaryBtnText={t('COMMON.NEXT')}
                    primaryBtnOnPress={() => navigationNoHistory({
                        name: nextScreen || ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE,
                        params: nextScreenParams,
                    })}
                    isPrimaryBtnDisabled={!logger.selectedLoggerType}
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={() => navigationNoHistory({
                        name: backScreen || ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE,
                    })}
                />
            </Container>
        </Layout>
    );
};
