import React, { useContext, useEffect, useMemo, useState } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { ASSET_TYPE, INSTANCE_TYPES } from '@src/constants';
import { useBackendEndpoints } from '@src/hooks/useBackendEndpoints';
import { getStatus } from '@src/lib';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { LayoutRemoveLogger } from '@src/components/Layout';
import { useNotification } from '@src/hooks/useNotification';
import { Pair } from '@src/components/shared-components/Status/Pair';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { Number } from '@src/components/Number';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { useScreen } from '@src/hooks/useScreen';
import { paddingLeft, paddingRight } from '@src/styles';
import { LoggerTypeSelection } from '@src/AddLogger/Screens/CheckAssetOrLogger/partials/LoggerTypeSelection';
import { REMOVE_LOGGER_SCREENS } from '../constant';
import { Description } from './Description';
import { ConfirmModal } from './ConfirmModal';

const backScreen = REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE;

export const CheckCode = ({ navigation, route }) => {
    const { type, scannedNumber, fromScreen } = route.params || {};
    const { isLandscape, wp, hp } = useScreen();
    const { t } = useTranslation();
    const { showSuccess } = useNotification();
    const [loggerChoiceOptions, setLoggerChoiceOptions] = useState(null);
    const { logger, asset, setLogger, setAsset, stepper } = useContext(RemoveLoggerContext);
    const { checkPairingByLogger, checkPairingByAsset, checkLoggerType } = useBackendEndpoints().requests;
    const [status, setStatus] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);
    const setInstance = useMemo(
        () => (type === INSTANCE_TYPES.ASSET ? setAsset : setLogger),
        [type, setAsset, setLogger],
    );

    const checkInstance = async () => {
        if (!instance.number) {
            setStatus(STATUSES.NOT_FOUND);
            return;
        }

        let selectedLoggerType = null;

        if (type === INSTANCE_TYPES.LOGGER && !instance.selectedLoggerType) {
            const resultCheckLoggerType = await checkLoggerType(instance.number);
            const { data } = resultCheckLoggerType;

            if (!data?.resultList) {
                setStatus(STATUSES.ERROR);
                return;
            }

            if (data.resultList.length === 0) {
                setStatus(STATUSES.NOT_FOUND);
                return;
            } else if (data.resultList.length > 1) {
                setLoggerChoiceOptions(data.resultList);
                setStatus(STATUSES.LOGGER_TYPE_SELECT);
                return;
            } else if (data.resultList.length === 1) {
                selectedLoggerType = data.resultList[0].loggerType;
                setInstance(curInstance => ({ ...curInstance, selectedLoggerType }));
            }
        }

        const result = type === INSTANCE_TYPES.ASSET
            ? await checkPairingByAsset(instance.number)
            : await checkPairingByLogger(instance.number, instance.selectedLoggerType || selectedLoggerType);

        if (result && result.data) {
            const { data } = result;

            if (data.assetTypeCode) {
                data.assetType = data.assetTypeCode; // TODO: delete this after backend assetTypeCode -> assetType
            }

            setInstance({ ...instance, ...data });
            const newStatus = getStatus({ type, data, setStatus, isRemoveFlow: true });

            if (newStatus === STATUSES.PAIRED) {
                showSuccess(t('REMOVE_LOGGER.SUCCESSFULLY_IDETIFIED', { number: instance.number }));
            }
        } else {
            setStatus(STATUSES.ERROR);
        }
    };

    useEffect(() => {
        checkInstance();
    }, [type]);

    const tryAgain = () => {
        stepper.prevStep();

        navigation.reset({ index: 0,
            routes: [{
                name: fromScreen || backScreen || REMOVE_LOGGER_SCREENS.ENTER_ASSET_OR_LOGGER_CODE,
            }] });
    };

    const openModal = () => {
        setIsModalVisible(true);
    };

    const onModalClose = () => {
        setIsModalVisible(false);
    };

    const styles = StyleSheet.create({
        image: {
            height: hp('26%'),
            width: wp('100%') - paddingRight - paddingLeft,
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
        imageWrapper: {
            alignItems: 'center',
            display: 'flex',
            marginTop: 23,
            ...(isLandscape && {
                marginTop: 0,
            }),
        },
        notFoundWrapper: {
            ...(isLandscape && {
                paddingTop: hp('7.3%'),
            }),
        },
        pairWrapper: {
            marginTop: 27,
            ...(isLandscape && {
                marginTop: 0,
                marginBottom: hp('5.1%'),
            }),
        },
    });

    const getRemoveTitle = () => {
        if (instance.assetType === ASSET_TYPE.PALLET) {
            return t('REMOVE_LOGGER.CONFIRM_REMOVE_TITLE_PALLET');
        }

        if (instance.assetType === ASSET_TYPE.CONTAINER) {
            return t('REMOVE_LOGGER.CONFIRM_REMOVE_TITLE_CONTAINER');
        }

        return t('REMOVE_LOGGER.CONFIRM_REMOVE_TITLE_OTHER');
    };

    const getRemoveImageSource = () => {
        if (instance.assetType === ASSET_TYPE.PALLET) {
            return require('@assets/images/remove_logger_pallet.png');
        }

        if (instance.assetType === ASSET_TYPE.CONTAINER) {
            return require('@assets/images/remove_logger_container.png');
        }

        return require('@assets/images/remove_logger_container.png');
    };

    if (status === STATUSES.LOGGER_TYPE_SELECT) {
        return (
            <LoggerTypeSelection
                isRemovalFlow
                loggerChoiceOptions={loggerChoiceOptions}
                nextScreen={REMOVE_LOGGER_SCREENS.CHECK_CODE}
                nextScreenParams={{ type }}
                backScreen={backScreen}
            />
        );
    }

    if (status === STATUSES.IDENTIFIED) {
        return (
            <LayoutRemoveLogger verticalAlign="center">
                <Container isPaddingTop>
                    <Status status={STATUSES.IDENTIFIED} assetType={asset.assetType} instanceType={type} />
                    <Number>{instance.number}</Number>
                    {!isLandscape && (<Description assetType={asset.assetType} instanceType={type} />)}
                </Container>

                <Container>
                    {isLandscape && (<Description assetType={asset.assetType} instanceType={type} />)}
                    <ButtonsBlock
                        secondaryBtnText={t('COMMON.BACK')}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    if (status === STATUSES.NOT_FOUND) {
        return (
            <LayoutRemoveLogger verticalAlign="center" wrapperStyle={styles.notFoundWrapper}>
                <Container isPaddingTop>
                    <Status status={STATUSES.NOT_FOUND} instanceType={type} />
                    <Number>{instance.number || scannedNumber}</Number>
                </Container>

                <Container>
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.TRY_AGAIN')}
                        primaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    if (status === STATUSES.PAIRED) {
        return (
            <LayoutRemoveLogger isWrapperNoPadding={isLandscape}>
                <Container>
                    <Title>
                        {getRemoveTitle()}
                    </Title>

                    {!isLandscape && (
                        <View style={styles.pairWrapper}>
                            <Pair
                                assetNumber={instance.assetNumber}
                                loggerNumber={instance.loggerNumber}
                                assetType={instance.assetType}
                                deviderTextCode="REMOVE_LOGGER.NO_LONGER"
                            />
                        </View>
                    )}

                    <View style={styles.imageWrapper}>
                        <Image
                            style={styles.image}
                            source={getRemoveImageSource()}
                            resizeMode="contain"
                        />
                    </View>

                    <ConfirmModal
                        isVisible={isModalVisible}
                        onClose={onModalClose}
                        type={type}
                    />
                </Container>

                <Container>
                    {isLandscape && (
                        <View style={styles.pairWrapper}>
                            <Pair
                                assetNumber={instance.assetNumber}
                                loggerNumber={instance.loggerNumber}
                                assetType={instance.assetType}
                                deviderTextCode="REMOVE_LOGGER.NO_LONGER"
                            />
                        </View>
                    )}
                    <ButtonsBlock
                        primaryBtnText={t('COMMON.REMOVE')}
                        secondaryBtnText={t('COMMON.CANCEL')}
                        primaryBtnOnPress={openModal}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    if (status === STATUSES.ERROR) {
        return (
            <LayoutRemoveLogger>
                <Container>
                    <Status status={STATUSES.ERROR} />
                </Container>

                <Container>
                    <ButtonsBlock
                        secondaryBtnText={t('COMMON.TRY_AGAIN')}
                        secondaryBtnOnPress={tryAgain}
                    />
                </Container>
            </LayoutRemoveLogger>
        );
    }

    return (
        <SpinnerScreen />
    );
};
