import React, { useContext, useEffect } from 'react';
import { StyleSheet, Text, ScrollView, View, Image } from 'react-native';
import { font, paddingLeft, paddingRight, theme } from '@src/styles';
import { Button } from '@src/components/shared-components/Button';
import { useTranslation } from 'react-i18next';
import { SCREEN_NAMES } from '@src/Root/constant';
import { StoreContext } from '@src/Contexts/StoreContext';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';

export const Home = ({ navigation }) => {
    const navigationNoHistory = useNavigationNoHistory();
    const { t } = useTranslation();
    const { layout, setLayout } = useContext(StoreContext);
    const { isLandscape, wp, hp } = useScreen();

    const blocks = [
        {
            title: 'MENU_ITEMS.ADD_LOGGER',
            description: 'MAIN_PAGE.ADD_LOGGER_DESC',
            imageUrl: require('@assets/images/home_add_logger.png'),
            btnVariant: 'primary' as const,
            btnText: t('COMMON.ADD'),
            btnUrl: SCREEN_NAMES.ADD_LOGGER,
            customStyles: () => ({}),
        },
        {
            title: 'MENU_ITEMS.REMOVE_LOGGER',
            description: 'MAIN_PAGE.REMOVE_LOGGER_DESC',
            imageUrl: require('@assets/images/home_remove_logger.png'),
            btnVariant: 'secondary' as const,
            btnText: t('COMMON.REMOVE'),
            btnUrl: SCREEN_NAMES.REMOVE_LOGGER,
            customStyles: (isLandscape) => ({
                marginTop: 83,
                ...(isLandscape && {
                    marginTop: 0,
                }),
            }),
        },
    ];

    useEffect(() => {
        setLayout({ ...layout, navigation });
    }, [navigation]);

    const styles = StyleSheet.create({
        block: {
            width: '100%',
            ...(isLandscape && {
                width: '42%',
            }),
        },
        container: {
            alignItems: 'stretch',
            flexGrow: 1,
            ...(isLandscape && {
                alignItems: 'center',
            }),
        },
        devider: {
            backgroundColor: theme.palette.common.dividerColor,
            height: hp('20.5%'),
            marginBottom: hp('2.5%'),
            width: 1,
        },
        deviderHorizontal: {
            backgroundColor: theme.palette.secondary.main,
            height: 1,
            marginTop: 70,
            width: '100%',
        },
        image: {
            height: 110,
            width: 110,
            ...(isLandscape && {
                height: wp('12%'),
                width: wp('12%'),
            }),
        },
        imageWrapper: {
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 24,
            marginTop: 13,
            ...(isLandscape && {
                marginBottom: hp('7%'),
                marginTop: hp('4%'),
            }),
        },
        text: {
            ...font.normal,
            color: theme.palette.common.black,
            fontSize: 14,
            letterSpacing: 0.18,
            lineHeight: 24,
            textAlign: 'center',
            ...(isLandscape && {
                fontSize: hp('2.5%'),
                lineHeight: hp('3%'),
            }),
        },
        title: {
            fontSize: 24,
            marginBottom: 5,
            ...(isLandscape && {
                ...font.light,
                fontSize: hp('6%'),
                lineHeight: hp('7%'),
            }),
        },
        wrapper: {
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'flex-start',
            paddingBottom: 70,
            paddingLeft,
            paddingRight,
            paddingTop: 48,
            ...(isLandscape && {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '80%',
            }),
        },
    });

    return (
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.wrapper}>
                {blocks.map(({ title, description, btnVariant, btnText, btnUrl, imageUrl, customStyles }, index) => (
                    <React.Fragment key={title}>
                        <View style={[styles.block, customStyles(isLandscape)]}>
                            <Text style={[styles.text, styles.title]}>{t(title)}</Text>
                            <Text style={styles.text}>{t(description)}</Text>
                            <View style={styles.imageWrapper}>
                                <Image
                                    style={styles.image}
                                    source={imageUrl}
                                    resizeMode="contain"
                                />

                            </View>
                            <Button
                                variant={btnVariant}
                                onPress={() => navigationNoHistory({ name: btnUrl })}
                            >
                                {btnText}
                            </Button>
                        </View>
                        {index === 0 && isLandscape && <View style={styles.devider} />}
                    </React.Fragment>
                ))}
                {!isLandscape && <View style={styles.deviderHorizontal} />}
            </View>
        </ScrollView>
    );
};
