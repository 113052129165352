import React, { useContext, useMemo } from 'react';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { LayoutRemoveLogger } from '@src/components/Layout';
import { SuccessScreen } from '@src/components/SuccessScreen';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { INSTANCE_TYPES } from '@src/constants';
import { useTranslation } from 'react-i18next';
import { SCREEN_NAMES } from '@src/Root/constant';

export const RemovalSuccess = ({ navigation, route }) => {
    const { t } = useTranslation();
    const { asset, logger } = useContext(RemoveLoggerContext);
    const { type } = route.params || {};
    const instance = useMemo(() => (type === INSTANCE_TYPES.ASSET ? asset : logger), [type, asset, logger]);

    return (
        <LayoutRemoveLogger isWrapperNoPadding isFullScreenProp>
            <SuccessScreen
                navigation={navigation}
                primaryBtn={{ text: t('MENU_ITEMS.ADD_LOGGER'), screenName: SCREEN_NAMES.ADD_LOGGER }}
            >
                <Status
                    status={STATUSES.REMOVED_SUCCESS}
                    assetNumber={instance.assetNumber}
                    loggerNumber={instance.loggerNumber}
                    assetType={instance.assetType}
                    deviderTextCode="REMOVE_LOGGER.NOT_PAIRED_WITH"
                />
            </SuccessScreen>
        </LayoutRemoveLogger>
    );
};
