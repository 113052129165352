import React, { useContext } from 'react';
import { View, StyleSheet, ScrollView, Dimensions } from 'react-native';
import { paddingLeft, paddingRight, paddingTop } from '@src/styles';
import { Stepper } from '@src/components/shared-components/Stepper';
import { RemoveLoggerContext } from '@src/RemoveLogger/RemoveLoggerContext';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { useScreen } from '@src/hooks/useScreen';
import { useTranslation } from 'react-i18next';

export const FLOWS = {
    ADD_LOGGER: 'addLogger',
    REMOVE_LOGGER: 'removeLogger',
};

type Props = {
    children: React.ReactNode,
    flow?: string,
    isWrapperNoPadding?: boolean,
    verticalAlign?: 'top' | 'center',
    wrapperStyle?: object,
    isFullScreenProp?: boolean,
    isStepperShown?: boolean,
};

export const Layout = ({
    children,
    flow,
    wrapperStyle = {},
    isWrapperNoPadding = false,
    verticalAlign = 'top',
    isFullScreenProp = false,
    isStepperShown = true,
}: Props) => {
    const { t } = useTranslation();
    const Context = flow === FLOWS.REMOVE_LOGGER ? RemoveLoggerContext : AddLoggerContext;
    const { stepper } = useContext(Context);
    const { isLandscape, wp, hp } = useScreen();

    const stepperLabelsAddFlow = [
        t('STEPPER.ASSET'),
        t('STEPPER.LOGGER'),
        t('STEPPER.PAIRING'),
    ];

    const stepperLabelsRemoveFlow = [
        t('STEPPER.ASSET_IDENTIFICATION'),
        t('STEPPER.LOGGER_REMOVAL'),
    ];

    const stepperLabels = flow === FLOWS.REMOVE_LOGGER ? stepperLabelsRemoveFlow : stepperLabelsAddFlow;

    const landscapeWidth = '80%';

    const styles = StyleSheet.create({
        alignCenter: {
            ...(isLandscape && {
                alignItems: 'center',
            }),
        },
        container: {
            flexGrow: 1,
            width: wp('100%'),
            ...(isLandscape && {
                alignItems: 'center',
            }),
        },
        containerFullWidth: {
            flexGrow: 1,
            width: Dimensions.get('window').width,
        },
        stepperWrapper: {
            paddingTop,
            ...(isLandscape && {
                paddingTop: hp('4.7%'),
                width: wp(landscapeWidth),
            }),
        },
        stepperWrapperPaddingsHorizontal: {
            paddingLeft: 55,
            paddingRight: 55,
        },
        wrapper: {
            flexGrow: 1,
            paddingLeft,
            paddingRight,
            ...(isLandscape && {
                paddingLeft: 0,
                paddingRight: 0,
                flexGrow: 0,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                width: wp(landscapeWidth),
            }),
        },
        wrapperNoPadding: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    });

    if (isFullScreenProp) {
        return (
            <ScrollView contentContainerStyle={styles.containerFullWidth}>
                {children}
            </ScrollView>
        );
    }

    return (
        <ScrollView contentContainerStyle={styles.container}>
            {isStepperShown && (
                <View style={[
                    styles.stepperWrapper,
                    flow === FLOWS.REMOVE_LOGGER && styles.stepperWrapperPaddingsHorizontal,
                ]}
                >
                    <Stepper labels={stepperLabels} stepperData={stepper} />
                </View>
            )}

            <View style={[
                styles.wrapper,
                isWrapperNoPadding && styles.wrapperNoPadding,
                verticalAlign === 'center' && styles.alignCenter,
                wrapperStyle,
            ]}
            >
                {children}
            </View>
        </ScrollView>
    );
};

export const LayoutRemoveLogger = ({ children, ...props }: Props) => {
    return (
        <Layout flow={FLOWS.REMOVE_LOGGER} {...props}>
            {children}
        </Layout>
    );
};
