import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from '@assets/translations/en/translations.json';
import deTranslations from '@assets/translations/de/translations.json';
import frTranslations from '@assets/translations/fr/translations.json';
import nlTranslations from '@assets/translations/nl/translations.json';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getLocales } from 'expo-localization';
import { languageOptions } from './constants';

const customInit = {
    type: '3rdParty',
    // TODO: fix any type
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    init: (i18n: any) => {
        if (Platform.OS === 'web') {
            const urlParams = new URLSearchParams(window.location.search);
            const showTranslationCodes = urlParams.get('showTranslationCodes');

            if (showTranslationCodes === 'true') {
                i18n.t = (scope: string) => scope;
            }
        }

        if (Platform.OS === 'web') {
            const language = localStorage.getItem('language');

            if (language) {
                i18n.changeLanguage(language);
            } else {
                const browserLanguage = getLocales()[0].languageCode;

                if (languageOptions.some((option) => option.value === browserLanguage)) {
                    i18n.changeLanguage(browserLanguage);
                } else {
                    i18n.changeLanguage('en');
                }
            }
        } else {
            AsyncStorage.getItem('language').then((language) => {
                if (language) {
                    i18n.changeLanguage(language);
                } else {
                    const deviceLanguage = getLocales()[0].languageCode;

                    if (languageOptions.some((option) => option.value === deviceLanguage)) {
                        i18n.changeLanguage(deviceLanguage);
                    } else {
                        i18n.changeLanguage('en');
                    }
                }
            });
        }
    },
};

i18n
    .use(initReactI18next)
    .use(customInit)
    .use({
        type: 'postProcessor',
        name: 'replace',
        process: (value) => {
            if (typeof value === 'string') {
                return value.replace(/\\n/g, '\n');
            }

            return value;
        },
    })
    .init({
        compatibilityJSON: 'v3',
        resources: {
            en: { translation: enTranslations },
            de: { translation: deTranslations },
            fr: { translation: frTranslations },
            nl: { translation: nlTranslations },
        },
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
        postProcess: ['replace'],
    });

export { i18n };
