import React, { useContext } from 'react';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { STATUSES, Status } from '@src/components/shared-components/Status';
import { SuccessScreen } from '@src/components/SuccessScreen';
import { Layout } from '@src/components/Layout';
import { useScreen } from '@src/hooks/useScreen';
import { useTranslation } from 'react-i18next';
import { SCREEN_NAMES } from '@src/Root/constant';

export const PairingSuccess = ({ navigation }) => {
    const { t } = useTranslation();
    const { isLandscape } = useScreen();
    const { asset, logger } = useContext(AddLoggerContext);

    return (
        <Layout isWrapperNoPadding={isLandscape} isFullScreenProp>
            <SuccessScreen
                navigation={navigation}
                primaryBtn={{ text: t('MENU_ITEMS.ADD_LOGGER'), screenName: SCREEN_NAMES.ADD_LOGGER }}
            >
                <Status
                    status={STATUSES.PAIRED_SUCCESS}
                    assetNumber={asset.number}
                    loggerNumber={logger.number}
                    assetType={asset.assetType}
                />
            </SuccessScreen>
        </Layout>
    );
};
