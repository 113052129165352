import * as React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { SideBar } from '@src/components/Header/SideBar';
import { useTranslation } from 'react-i18next';
import { useContext, useMemo } from 'react';
import { StoreContext } from '@src/Contexts/StoreContext';
import { Home } from '@src/Home';
import { Login } from '@src/Login';
import { AddLoggerPath } from '@src/AddLogger/AddLoggerPath';
import { RemoveLoggerPath } from '@src/RemoveLogger/RemoveLoggerPath';
import { theme } from '@src/styles';
import { NoAccess } from '@src/NoAccess';
import { getPageTitle } from '@src/lib';
import { SpinnerScreen } from '@src/components/SpinnerScreen';
import { useScreen } from '@src/hooks/useScreen';
import { useHeader } from '@src/hooks/useHeader';
import { Platform } from 'react-native';
import { SCREEN_NAMES } from './constant';

const Drawer = createDrawerNavigator();

function MyDrawer() {
    const { t } = useTranslation();
    const { screenOptions } = useHeader();
    const { isLandscape, wp } = useScreen();
    const { auth, userInfo } = useContext(StoreContext);
    const { accessToken, isLogin, isRefreshingToken } = auth;
    const hasRole = userInfo?.groups?.includes('ASSET_PAIRING');

    const screens = useMemo(() => {
        if (isLogin) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.NO_ACCESS}
                    component={SpinnerScreen}
                    options={{ title: getPageTitle() }}
                />
            );
        } else if (!accessToken && !isRefreshingToken) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.LOGIN}
                    component={Login}
                    options={{ title: getPageTitle() }}
                />
            );
        } else if (accessToken && userInfo && !hasRole) {
            return (
                <Drawer.Screen
                    name={SCREEN_NAMES.NO_ACCESS}
                    component={NoAccess}
                    options={{ title: getPageTitle() }}
                />
            );
        } else if (accessToken && hasRole) {
            return (
                <>
                    <Drawer.Screen
                        name={SCREEN_NAMES.ACTIONS}
                        component={Home}
                        options={{
                            title: getPageTitle(),
                            headerTitle: t(`MENU_ITEMS.${SCREEN_NAMES.ACTIONS}`),
                        }}
                    />
                    <Drawer.Screen
                        name={SCREEN_NAMES.ADD_LOGGER}
                        component={AddLoggerPath}
                        options={{
                            headerShown: false,
                        }}
                    />
                    <Drawer.Screen
                        name={SCREEN_NAMES.REMOVE_LOGGER}
                        component={RemoveLoggerPath}
                        options={{
                            headerShown: false,
                        }}
                    />
                </>
            );
        }
    }, [accessToken, userInfo, hasRole, isLogin, isRefreshingToken]);

    return (
        <Drawer.Navigator
            screenOptions={{
                ...screenOptions,
                headerShown: !!accessToken,
                ...(Platform.OS !== 'web' && {
                    headerLeftContainerStyle: {
                        paddingLeft: 16,
                        ...(isLandscape && {
                            paddingLeft: wp('2.5%'),
                        }),
                    },
                    headerRightContainerStyle: {
                        paddingRight: 16,
                        ...(isLandscape && {
                            paddingRight: wp('2.1%'),
                        }),
                    },
                }),
                drawerType: 'front',
                drawerStyle: {
                    width: wp('85%'),
                    ...(isLandscape && {
                        width: wp('30%'),
                    }),
                },
                sceneContainerStyle: {
                    backgroundColor: theme.palette.common.white,
                },
            }}
            drawerContent={SideBar}
        >
            {screens}
        </Drawer.Navigator>
    );
}

export const Root = () => {
    const { layout } = useContext(StoreContext);

    return (
        <NavigationContainer>
            {layout.isRestarting ? <SpinnerScreen /> : <MyDrawer />}
        </NavigationContainer>
    );
};
