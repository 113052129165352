import React, { useContext, useState } from 'react';
import { View, Image, ActivityIndicator, StyleSheet } from 'react-native';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { useTranslation } from 'react-i18next';
import { paddingLeft, paddingRight, theme } from '@src/styles';
import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ASSET_TYPE } from '@src/constants';
import { Pair } from '@src/components/shared-components/Status/Pair';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import { getEnterCodeScreenStyles } from '../styles';
import { ADD_LOGGER_SCREENS } from '../constant';

export const ShowPairingInstruction = () => {
    const navigationNoHistory = useNavigationNoHistory();
    const { t } = useTranslation();
    const { isLandscape, wp, hp } = useScreen();
    const { asset, logger, stepper } = useContext(AddLoggerContext);
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);
        navigationNoHistory({ name: ADD_LOGGER_SCREENS.TAKE_PICTURE_INIT });
        setIsLoading(false);
    };

    const onDismiss = () => {
        stepper.setCurStep(1);
        navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE });
    };

    const sharedStyles = getEnterCodeScreenStyles({ hp });

    const styles = StyleSheet.create({
        image: {
            height: hp('26%'),
            width: wp('100%') - paddingRight - paddingLeft,
            ...(isLandscape && {
                height: hp('35%'),
                width: wp('34%'),
            }),
        },
        imageWrapper: {
            marginTop: 38,
            ...(isLandscape && {
                marginTop: hp('3%'),
            }),
        },
        pairWrapper: {
            marginTop: 33,
            ...(isLandscape && {
                marginTop: 0,
                marginBottom: hp('5.6%'),
            }),
        },
    });

    const getTitle = () => {
        if (asset.assetType === ASSET_TYPE.CONTAINER) {
            return t('ADD_LOGGER.ATTACH_LOGGER_INSIDE_CONTAINER');
        }

        if (asset.assetType === ASSET_TYPE.PALLET) {
            return t('ADD_LOGGER.ATTACH_LOGGER_TO_PALLET');
        }

        return t('ADD_LOGGER.PLACE_OTHER_LOGGER');
    };

    const getImageSource = () => {
        if (asset.assetType === ASSET_TYPE.CONTAINER) {
            return require('@assets/images/place_logger_inside_container.png');
        }

        if (asset.assetType === ASSET_TYPE.PALLET) {
            return require('@assets/images/place_logger_to_pallet.png');
        }

        return require('@assets/images/place_logger_inside_container.png');
    };

    return (
        <Layout verticalAlign="center">
            <Container>
                <Title>
                    {getTitle()}
                </Title>

                {!isLandscape && (
                    <Pair
                        style={styles.pairWrapper}
                        loggerNumber={logger.number}
                        isLoggerActive={logger.isLoggerActive}
                        assetNumber={asset.number}
                        assetType={asset.assetType}
                        deviderTextCode="ADD_LOGGER.WILL_BE_PAIRED_WITH"
                    />
                )}

                <View style={[sharedStyles.imageWrapper, styles.imageWrapper]}>
                    <Image
                        style={styles.image}
                        source={getImageSource()}
                        resizeMode="contain"
                    />
                </View>
            </Container>

            <Container>
                {isLandscape && (
                    <Pair
                        style={styles.pairWrapper}
                        loggerNumber={logger.number}
                        assetNumber={asset.number}
                        isLoggerActive={logger.isLoggerActive}
                        assetType={asset.assetType}
                        deviderTextCode="ADD_LOGGER.WILL_BE_PAIRED_WITH"
                    />
                )}

                <ButtonsBlock
                    primaryBtnText={isLoading
                        ? <ActivityIndicator size="small" color={theme.palette.common.white} />
                        : t('COMMON.NEXT')}
                    isPrimaryBtnDisabled={isLoading}
                    primaryBtnOnPress={onConfirm}
                    secondaryBtnText={t('COMMON.CANCEL')}
                    secondaryBtnOnPress={onDismiss}
                />
            </Container>
        </Layout>
    );
};
